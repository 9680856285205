import router from '@/router'
import axios from '@/axios/auth-instance'

export default {
  namespaced:true,
  state: {
      farms:null,
      farmsOption: null,
  },
  mutations: {
   
      setFarm(state,farms){
        state.farms = farms;
      },
      setFarmOptions(state,farms){
        state.farmsOption = farms;
      }  
  },
  actions: {
    getAllFarms({commit,dispatch}){
        axios.get('/all-farms')
          .then(res=> {
              commit('setFarmOptions',res.data);
          })
          .catch(error=>console.log(error))
    },
  		getFarms({commit,dispatch},payload){
        axios.get('/farms?page='+payload.page+'&&q='+payload.key+'&&status='+payload.status)
             .then(res=> {
                commit('setFarm',res.data);
             })
             .catch(error=>console.log(error))
  		},
      storeFarm({commint,dispatch},payload){
        return axios.post('/farm/store',payload)
      },
      editFarm({commit,dispatch},id){
       return axios.get('/farm/'+id+'/edit')
             .then(res=> {
               return res;
             })
             .catch(error=>console.log(error))
      },
      updateFarm({commit,dispatch},payload){
        return axios.post('/farm/'+payload.id+'/update',payload.formData)
            
      },
      deleteFarm({commit,dispatch},id){
        return  axios.get('/farm/'+id+'/delete')
            
      },
  },
  getters: {
    farms(state){
      return state.farms;
    },
    farmsOption(state){
      return state.farmsOption;
    }
  }
};

