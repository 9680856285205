<template>
	<main class="agro-main">
     <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/dashboard"><i class="fa fa-home"></i>  Home</router-link></li>
      <li class="breadcrumb-item"> <router-link to="/user">Users</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">Change Password</li>
    </ol>
    <div class="agro-content agro-form">
        <h3> <span>Change Password</span></h3>
        <form class="row"  @submit.prevent="changePassword()" enctype="multipart/form-data">
         <!--  <div class="form-group col-md-4" :class="{invalid:$v.fields.old_password.$error}">
            <label for="old_password">Old Password <span style="color:red">*</span></label>
            <input  @blur="$v.fields.old_password.$touch()" v-model="fields.old_password" type="password" class="form-control">
            <p class="error" v-if="$v.fields.old_password.$error &&  !$v.fields.old_password.required">This field must not be empty.</p>
          </div> -->
          <div class="form-group col-md-4" :class="{invalid:$v.fields.new_password.$error}">
            <label for="new_password">New Password <span style="color:red">*</span></label>
            <input  @blur="$v.fields.new_password.$touch()" v-model="fields.new_password" type="password" class="form-control">
            <p class="error" v-if="$v.fields.new_password.$error &&  !$v.fields.new_password.required">This field must not be empty.</p>
          </div>
          <div class="form-group col-md-4" :class="{invalid:($v.fields.password.$error || password_error)}">
            <label for="password">Confirm Password <span style="color:red">*</span></label>
            <input  @blur="$v.fields.password.$touch()" v-model="fields.password" type="password" class="form-control">
            <p class="error" v-if="$v.fields.password.$error &&  !$v.fields.password.required">This field must not be empty.</p>
            <p class="error" v-if="password_error">Password doesnot match.</p>
          </div>
          <div class="form-group col-md-12 submit-container">
            <button type="submit" class="btn agro-submit-btn">Update Password</button>
            <div class="loader" style="width:auto;" v-if="is_loading">
              <img class="logo" :src="require('@/assets/loader.gif')" alt="">
            </div>
          </div>
        </form>
    </div>
  </main>
</template>
<script>
  import { ref } from "vue";
  import {required,email} from 'vuelidate/lib/validators';
  export default {
    data(){
      return {
          id:this.$route.params.id,
          is_loading:false,
          is_submit:false,
          password_error:false,
          fields:{
              // old_password:'',
              new_password:'',
              password:'',
          }
      }
    },
    validations:{
      fields:{
        // old_password:{required},
        new_password:{required},
        password:{required},
      }
    },
    methods:{
      changePassword(){
          this.is_loading = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
              this.is_loading = false;
              return;
          }

          this.is_submit = true;
          this.password_error = this.fields.new_password!=this.fields.password?true:false;

          if(this.password_error){
            this.is_loading = false;
            return;
          }

          let data = new FormData();
              data.append('user_id',this.id);
              data.append('password',this.fields.password);

              this.$store.dispatch('user/changePassword',data)
                .then(res=> {
                     this.is_loading = false;

                      this.$confirm({
                          title: 'Success',
                          message: 'Password Change Success.',
                          button: {
                            yes: 'OK',
                          },
                          callback: confirm => {
                            if (confirm) {
                              this.$router.replace('/user')
                            }
                          }
                        }
                       )
                 })
                .catch((error) => {
                  if (error.response) {
                      console.log(error.response.data);
                  }else if (error.request) {
                      console.log(error.request);
                  }else {
                      console.log('Error', error.message);
                  }
                  this.is_loading = false;
              });
      }, 
    
    },
    mounted(){
     let id = this.$route.params.id
    }
  }

</script>
<style></style>