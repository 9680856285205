<template>
	<main class="agro-main animate__animated animate__fadeIn">
     <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/dashboard"><i class="fa fa-home"></i>  Home</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">Sliders</li>
    </ol>
    <div class="agro-content  animate__animated animate__fadeIn">
      <h3> 
        <div class="agro-title-content">
          <span>SLIDER LIST</span>
        </div>
        <div class="agro-filter">
          <div class="search-wrapper">
            <input type="text" @keyup.enter="paginateData(1)" v-model="search" placeholder="search">
            <a  v-if="is_searched" @click="clearSearch()" class="btn-search" >
              <i @click="clearSearch()" class="fa fa-times"></i>
            </a> 
            <a v-if="!is_searched" @click="paginateData(1)"  class="btn-search">
              <i class="fa fa-search"></i>
            </a>
          </div>
          <a class="btn-warning" @click="addSlider"><i class="fa fa-plus"></i></a>
        </div>
      </h3>
      <div class="agro-table-content">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Featured Image</th>
              <th>Title</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr  v-if="is_loading">
              <td colspan="4" > 
                   <div class="loader">
                    <img class="logo" :src="require('@/assets/loader.gif')" alt="">
                  </div>
              </td>
            </tr>
             <tr v-if="sliders.total==0  && !is_loading">
                <td colspan="4">No Data found.</td>
              </tr>
            <tr v-if="!is_loading" v-for="slider in sliders.data">
              <td>
                <img v-if="slider.image_file!=null" class="avatar" :src="imageBase(slider.image_file)"/>
                <div v-if="slider.image_file==null" class="avatar">{{(slider.title).substr(0,1)}}</div>
              </td>
              <td>{{slider.title}}</td>
              <td><span v-bind:class="{'un-active':slider.status==0,'active':slider.status==1}"></span></td>
              <td>
                <a class="btn-agro agro-edit" @click="editSlider(slider.id)"><i class="fa fa-edit"></i></a>
                <a class="btn-agro agro-delete" @click="deleteSlider(slider.id)"><i class="fa fa-trash"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination :data="sliders" @pagination-change-page="paginateData" :limit="4"></pagination>
      </div>
    </div>
  </main>
</template>
<script>
  export default {
    data(){
      return {
        is_loading:false,
        timeout:500,
        search:'',
        is_searched:false,
      }
    },
    computed:{
      sliders(){
        return !this.$store.getters['slider/sliders']?{}:this.$store.getters['slider/sliders'];
      },
    },
    methods:{
      clearSearch(){
        this.search = '';
        this.is_searched = false;
        this.paginateData(1);
      },
      ucfirst(string){
        return string[0].toUpperCase() + string.slice(1)
      },
      imageBase(_img){

        const url = _img.split("/");

        _img = url[url.length-1];
        _img = '/storage/sliders/thumbnail/'+_img;
        return this.$hostname+_img;
      },
      paginateData(page = 1){
        let _this = this;
        this.is_searched = this.search==''?false:true;
        this.is_loading = true;
        this.$store.dispatch('slider/getSliders',{page:page,key:this.search}).then(res=>{
             setTimeout(function(){
                 _this.is_loading = false;

             },_this.timeout)
        });
      },
      editSlider(id){
        this.$router.push(`slider/${id}/edit`);
      },
      addSlider(){
        this.$router.push(`slider/create`);
      },
      deleteSlider(id){
        let _this = this;
        this.$confirm(
          {
            title: 'Are you sure?',
            message: 'Once deleted it cannot be recover.',
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                   this.$store.dispatch('slider/deleteSlider',id).then((res)=>{
                      this.$store.dispatch('slider/getSliders',{page:1,key:this.search});
                   });
              }
            }
          }
        )
      },
    },
    mounted(){
      let _this = this;
      this.is_loading = true;
      this.$store.dispatch('slider/getSliders',{page:1,key:this.search}).then(res=>{
         setTimeout(function(){
                 _this.is_loading = false;

             },_this.timeout)

      });

    }
  }

</script>
<style></style>