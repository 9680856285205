<template>
	<main class="agro-main">
     <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/dashboard"><i class="fa fa-home"></i>  Home</router-link></li>
      <li class="breadcrumb-item"> <router-link to="/slider">Sliders</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">{{isEditing==true?'Edit':'Add'}} Slider</li>
    </ol>
    <div class="agro-content agro-form">
        <h3> <span>{{isEditing==true?'EDIT':'ADD'}} SLIDER</span></h3>
        <form class="row slider-form"  @submit.prevent="isEditing==true?updateSlider():createSlider()" enctype="multipart/form-data">

          <div class="form-group col-md-4" :class="{invalid:$v.fields.title.$error}">
            <label for="title">Title <span style="color:red">*</span></label>
            <input  @blur="$v.fields.title.$touch()" v-model="fields.title" type="text" class="form-control">
            <p class="error" v-if="$v.fields.title.$error &&  !$v.fields.title.required">This field must not be empty.</p>
          </div>
           <div class="form-group col-md-4">
              <strong for="name">Status</strong>
              <label class="switch">
                <input type="checkbox" name="status" value="1" v-model="fields.status" >
                <span class="slider round"></span>
              </label>
          </div>

          <div class="form-group  col-md-12" >
            <label for="">Featured Image (max file upload 1 MB)</label>
            <div class="file-container">
              <!-- <span @click="removeImage" v-if="fields.image_file!=null"><i class="fa fa-close"></i></span> -->

              <div class="file-preview"  @click="fileInputTrigger">
                <a href="javascript:void(0);"><i class="fa fa-image"></i></a>
                <img v-if="fields.image_file!=null" :src="_file" class="image-view">
              </div>
              <input  @change="handleImageSelected" type="file" 
                      ref="fileInput" class="hide-element" 
                      accept="image/gif, image/jpeg, image/png">
            </div>
            <p class="error-img" v-if="slider_image_validation_error">File upload max: 1MB</p>
          </div>
          <div class="form-group col-md-4 submit-container">
            <button type="submit" class="btn agro-submit-btn">{{isEditing==true?'Update':'Submit'}} </button>
            <div class="loader" v-if="is_loading">
              <img class="logo" :src="require('@/assets/loader.gif')" alt="">
            </div>
          </div>
        </form>
    </div>
  </main>
</template>
<script>
  import { ref } from "vue";
  import {required,email} from 'vuelidate/lib/validators';
  export default {
    data(){
      return {
          id:this.$route.params.id,
          isEditing : false,
          is_loading:false,
          file_touched:false,
          file:null,
          slider_image_validation_error:false,
          is_changed:false,
          fields:{
              title:null,
              status:false,
              image:null,
              image_file:null,
          },
      }
    },
    validations:{
      fields:{
        title:{required},
      }
    },
    computed:{
      _file(){
        if(this.is_changed)
          return this.fields.image_file;
        
        return this.$hostname+this.fields.image_file;
      }
    },
    watch:{

      file(file,oldFile){
        this.is_changed = true;
        if(!(file instanceof File)) return;
        let _this = this;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener('load',function(){
          _this.fields.image = fileReader.result;
          _this.fields.image_file = fileReader.result;
        })
      }
    },
    methods:{
     
      createSlider(){
          this.is_loading = true;
          this.$v.$touch();
          if(this.$v.$invalid || this.slider_image_validation_error===true) {
              this.is_loading = false;
              return;
          }

          let data = new FormData();
              data.append('title',this.fields.title);
              data.append('status',this.fields.status);
              data.append('file',this.file);

            console.log('hey');
           this.$store.dispatch('slider/storeSlider',data)
              .then(res=> {
                   this.is_loading = false;
                   this.$router.replace('/slider')
               })
              .catch((error) => {
                if(error.response) {
                    console.log(error.response.data);
                }else if(error.request) {
                    console.log(error.request);
                }else {
                    console.log('Error', error.message);
                }
                this.is_loading = false;

            });
      }, 
      updateSlider(){
          let _this = this;
          this.is_loading = true;
          this.file_touched = true;
          this.$v.$touch();
          if (this.$v.$invalid || this.slider_image_validation_error==true) {
              this.is_loading = false;
              return;
          }
         let data = new FormData();
              data.append('title',this.fields.title);
              data.append('description',this.fields.description);
              data.append('status',this.fields.status);
              data.append('file',this.file);


          this.$store.dispatch('slider/updateSlider',{id:this.id,formData:data})
              .then(res=> {
                      this.is_loading = false;

                      this.$confirm({
                          title: 'Success',
                          message: 'Slider Successfully Updated.',
                          button: {
                            yes: 'OK',
                          },
                          callback: confirm => {
                            if (confirm) {
                              this.$router.replace('/slider')
                            }
                          }
                        }
                       )

               })
              .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                }else if (error.request) {
                    console.log(error.request);
                }else {
                    console.log('Error', error.message);
                }
                this.is_loading = false;

            });

      },
      handleImageSelected(event){
        console.log('images',event.target.files);
        if(event.target.files.length==0){
          this.file = null;
          this.fields.image = null;
          return;
        } 
        this.file = event.target.files[0];

        this.file_touched = true;

        if(this.file != null && this.file.size>1000000)
          this.slider_image_validation_error = true; 

      },
     
      removeImage(){
        this.file = null;
        this.fields.image = null;
        this.fields.image_file = null;
        if(this.file!=null && this.file.size>1000000){
          this.slider_image_validation_error = true; 
        }else{
          this.slider_image_validation_error = false;
        }
      },
      fileInputTrigger(){
        this.$refs.fileInput.click();
      },
      getAndSetData(){
          // console.log(this.id);
            this.$store.dispatch('slider/editSlider',this.id).then((res)=>{
              // console.log('edit',res);
              this.fields.title       = res.data.title;
              this.fields.image       = res.data.image;
              this.fields.image_file  = res.data.image_file;
              this.fields.status      = res.data.status=="0"?false:true;
            })
        }
    },
    mounted(){
     let id = this.$route.params.id
     this.isEditing = (id===undefined)?false:true;
     if(this.isEditing)
     this.getAndSetData();

     
    }
  }

</script>
<style>
    .select.invalid input{
       border:1px solid white !important;
    }
    .select.invalid .v-select.vs--single.vs--searchable {
        border: 1px solid red;
        border-radius: 6px;
     }

    .slider-form .switch {
       position: relative;
       display: inline-block;
       width: 60px;
       height: 34px;
    }

    .slider-form .switch input { 
       opacity: 0;
       width: 0;
       height: 0;
    }

    .slider-form .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider-form .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider-form input:checked + .slider {
      background-color: #2196F3;
    }

    .slider-form input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    .slider-form input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    .slider-form .slider.round {
      border-radius: 34px;
    }

    .slider-form .slider.round:before {
      border-radius: 50%;
    }

    .slider-form  strong{
        display: block;
        font-size: 14px;
        font-weight: normal;
        padding-bottom: 10px;
    }
    .input-repeat {margin: 10px 0;display: flex;}

    .input-repeat input {width: 50%;border: 1px solid #cdcdcd;padding: 6px;border-radius: 5px;}

    .input-repeat span {display: flex;margin-left: 10px;background: red;cursor: pointer;width: 35px;height: 35px;text-align: center;align-items: center;color: white;border-radius: 99px;justify-content: center;}

    .input-repeat span:hover {background: #3db47f;}

    .add-more {
      padding: 6px 10px;
      background: #29d;
      color: white !important;
      border-radius: 5px;
      cursor:pointer;
      margin-top: 10px;
      display: inline-block;
    }
  .r-m {background: #f3f6f7;padding: 25px;border-radius: 5px;margin-top: 10px;margin-bottom: 10px;}
  .r-m label {display: block;}
  .error-img{
    color:red;
    margin-top:10px;
  }
  .submit-container{
    display:flex;
    align-items:center;
    margin-top:20px;
  }
  .submit-container button{
    margin:0px !important;
  }
  .submit-container img{
      margin-left:10px;
  }
</style>