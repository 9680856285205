<template>
	<main class="agro-main">
     <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/dashboard"><i class="fa fa-home"></i>  Home</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">Blogs</li>
    </ol>
    <div class="agro-content  animate__animated animate__fadeIn">
       <h3> 
        <div class="agro-title-content">
          <span>BLOG LIST</span>
        </div>
        <div class="agro-filter">
         <div class="search-wrapper">
            <input type="text" @keyup.enter="paginateData(1)" v-model="search" placeholder="search">
            <a  v-if="is_searched" @click="clearSearch()" class="btn-search" >
              <i @click="clearSearch()" class="fa fa-times"></i>
            </a> 
            <a v-if="!is_searched" @click="paginateData(1)"  class="btn-search">
              <i class="fa fa-search"></i>
            </a>
          </div>
          <a class="btn-warning" @click="addBlog"><i class="fa fa-plus"></i></a>
        </div>
      </h3>

      
      <div class="agro-table-content">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Featured Image</th>
              <th>Category</th>
              <th>Title</th>
              <th>Desc</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
             <tr  v-if="is_loading">
              <td colspan="6" > 
                   <div class="loader">
                    <img class="logo" :src="require('@/assets/loader.gif')" alt="">
                  </div>
              </td>
            </tr>
             <tr v-if="blogs.total==0 && !is_loading">
                <td colspan="6">No Data found.</td>
              </tr>
            <tr v-if="!is_loading" v-for="blog in blogs.data">
              <td>
                <img v-if="blog.image_file!=null" class="avatar" :src="imageBase(blog.image_file)"/>
                <div v-if="blog.image_file==null" class="avatar">{{(blog.title).substr(0,1)}}</div>
              </td>
              <td>{{blog.category_name}}</td>
              <td>{{blog.title}}</td>
              <td>{{(blog.description).substr(0,40)}}...</td>
              <td><span v-bind:class="{'un-active':blog.status==0,'active':blog.status==1}"></span></td>
              <td>
                <a class="btn-agro agro-edit" @click="editBlog(blog.id)"><i class="fa fa-edit"></i></a>
                <a class="btn-agro agro-delete" @click="deleteBlog(blog.id)"><i class="fa fa-trash"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination :data="blogs" @pagination-change-page="paginateData" :limit="4"></pagination>
      </div>
    </div>
  </main>
</template>
<script>
  export default {

    data(){
      return {
        is_loading:false,
        timeout:500,
        search:'',
        is_searched:false
      }
    },
    computed:{
      blogs(){
        return !this.$store.getters['blog/blogs']?{}:this.$store.getters['blog/blogs'];
      },
    },
    methods:{
      clearSearch(){
        this.search = '';
        this.is_searched = false;
        this.paginateData(1);
      },
      ucfirst(string){
        return string[0].toUpperCase() + string.slice(1)
      },
      imageBase(_img){

        const url = _img.split("/");

        _img = url[url.length-1];
        _img = '/storage/blogs/thumbnail/'+_img;
        return this.$hostname+_img;
      },
      paginateData(page = 1){

        let _this = this;
        this.is_searched = this.search==''?false:true;
        this.is_loading = true;
        this.$store.dispatch('blog/getBlogs',{page:page,key:this.search}).then(res=>{
             setTimeout(function(){
                 _this.is_loading = false;

             },_this.timeout)
        });
      },
      editBlog(id){
        this.$router.push(`blog/${id}/edit`);
      },
      addBlog(){
        this.$router.push(`blog/create`);
      },
      deleteBlog(id){
        let _this = this;
        this.$confirm(
          {
            title: 'Are you sure?',
            message: 'Once deleted it cannot be recover.',
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                   this.$store.dispatch('blog/deleteBlog',id).then((res)=>{
                      this.$store.dispatch('blog/getBlogs',{page:1,key:this.search});
                   });
              }
            }
          }
        )
      },
    },
    mounted(){
      let _this = this;
      this.is_loading = true;
      this.$store.dispatch('blog/getBlogs',{page:1,key:this.search}).then(res=>{
         setTimeout(function(){
                 _this.is_loading = false;

             },_this.timeout)

      });
     this.$store.dispatch('category/getAllCategories');

    }
  }

</script>
<style></style>