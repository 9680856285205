import axios from 'axios';
import store from '@/store/store';

const instance = axios.create({
  baseURL: 'https://krishiapi.demo.momtech.com.np/api'
})
  // baseURL: 'https://krishi-app.test/api'

// instance.defaults.headers.common['SOMETHING'] = 'something'


instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if(error.response.data.api_status=='401' || error.response.status==401){
      store.dispatch('auth/clientLogout');
    }
    // console.log('ersr',error.response);
    return Promise.reject(error);
  });
export default instance