import Vue from 'vue'
import Vuex from 'vuex'
import authModule from '@/store/modules/auth-module'
import userModule from '@/store/modules/user-module'
import applicationModule from '@/store/modules/application-module'
import categoryModule from '@/store/modules/category-module'
import blogModule from '@/store/modules/blog-module'
import programModule from '@/store/modules/program-module'
import farmModule from '@/store/modules/farm-module'
import sliderModule from '@/store/modules/slider-module'
import commonModule from '@/store/modules/common-module'
import dashboardModule from '@/store/modules/dashboard-module'

Vue.use(Vuex)

export default new Vuex.Store({

          modules: {
              auth: authModule,
              user: userModule,
              application : applicationModule,
              category : categoryModule,
              blog : blogModule,
              farm : farmModule,
              program : programModule,
              slider : sliderModule,
              common : commonModule,
              dashboard : dashboardModule,
          }
})
