<template>
	<main class="agro-main">
   
     <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/dashboard"><i class="fa fa-home"></i>  Home</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">CATEGORIES</li>
    </ol>
    <div class="agro-content  animate__animated animate__fadeIn">
      <h3> 
        <div class="agro-title-content">
          <span>CATEGORY LIST</span>
        </div>
        <div class="agro-filter">
         <div class="search-wrapper">
            <input type="text" @keyup.enter="paginateData(1)" v-model="search" placeholder="search">
            <a  v-if="is_searched" @click="clearSearch()" class="btn-search" >
              <i @click="clearSearch()" class="fa fa-times"></i>
            </a> 
            <a v-if="!is_searched" @click="paginateData(1)"  class="btn-search">
              <i class="fa fa-search"></i>
            </a>
          </div>
          <a class="btn-warning" @click="addCategory"><i class="fa fa-plus"></i></a>
        </div>
      </h3>
      <div class="agro-table-content">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr  v-if="is_loading">
              <td colspan="3" > 
                   <div class="loader">
                    <img class="logo" :src="require('@/assets/loader.gif')" alt="">
                  </div>
              </td>
            </tr>
            <tr v-if="categories.total==0 && !is_loading">
              <td colspan="3">No Data found.</td>
            </tr>
            <tr v-if="!is_loading" v-for="category in categories.data">
              <td>{{category.name}}</td>
              <td>
                <span v-bind:class="{'un-active':category.status==0,'active':category.status==1}"></span>
              </td>
              <td>
                <a class="btn-agro agro-edit" @click="editCategory(category.id)"><i class="fa fa-edit"></i></a>
                <a class="btn-agro agro-delete" @click="deleteCategory(category.id)"><i class="fa fa-trash"></i></a>
              </td>
            </tr>
           
          </tbody>
        </table>
        <pagination :data="categories" @pagination-change-page="paginateData" :limit="4"></pagination>
      </div>
    </div>
  </main>
</template>
<script>
  export default {

    data(){
      return {
        is_loading:false,
        timeout:500,
        search:'',
        is_searched:false
      }
    },
    computed:{
      categories(){
        return !this.$store.getters['category/categories']?{}:this.$store.getters['category/categories'];
      },
    },
    methods:{
      clearSearch(){
        this.search = '';
        this.is_searched = false;
        this.paginateData(1);
      },
      ucfirst(string){
        return string[0].toUpperCase() + string.slice(1)
      },
      imageBase(_img){
        return this.$hostname+_img;
      },
      paginateData(page = 1){
        let _this = this;
        this.is_searched = this.search==''?false:true;
        this.is_loading = true;
        this.$store.dispatch('category/getCategories',{page:page,key:this.search}).then(res=>{
             setTimeout(function(){
                 _this.is_loading = false;

             },_this.timeout)
        });
      },
      editCategory(id){
        this.$router.push(`category/${id}/edit`);
      },
      addCategory(){
        this.$router.push(`category/create`);
      },
      deleteCategory(id){
        let _this = this;
        this.$confirm(
          {
            title: 'Are you sure?',
            message: 'Once deleted it cannot be recover.',
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                   this.$store.dispatch('category/deleteCategory',id).then((res)=>{
                      this.$store.dispatch('category/getCategories',{page:1,key:this.search});
                   });
              }
            }
          }
        )
      },
    },
    mounted(){
      let _this = this;
      this.is_loading = true;
      this.$store.dispatch('category/getCategories',{page:1,key:this.search}).then(res=>{
         setTimeout(function(){
                 _this.is_loading = false;

             },_this.timeout)
      });
      console.log('categories',this.categories);
    }
  }

</script>
<style>
  span.un-active {
      display: block;
      width: 16px;
      background: #ff0100;
      height: 16px;
      border-radius: 41px;
  }
  span.active {
      display: block;
      
      background: #3db47f;
      width: 16px;
      height: 16px;
      border-radius: 41px;
  }
</style>