<template>
    <main class="agro-main">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <router-link to="/dashboard"><i class="fa fa-home"></i> Home</router-link>
            </li>
            <li class="breadcrumb-item">
                <router-link to="/farm">Farms</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{isEditing==true?'Edit':'Add'}} Farm</li>
        </ol>
        <div class="agro-content agro-form">
            <h3> <span>{{isEditing==true?'EDIT':'ADD'}} FARM</span></h3>
            <form class="row farm-form" @submit.prevent="isEditing==true?updateFarm():createFarm()" enctype="multipart/form-data">
                <div class="form-group col-md-4  select">
                    <label for="">Status </label>
                    <v-select label="text" :options="status_list" :value="status" @input="setStatusSelected"></v-select>
                </div>
                <div class="form-group col-md-4">
                    <label for="farm_name">Farm Name </label>
                    <input @blur="$v.fields.farm_name.$touch()" v-model="fields.farm_name" type="text" class="form-control">
                </div>
                <div class="form-group col-md-4 select">
                    <label for="">User </label>
                    <v-select label="text" :options="users" :value="user" @input="setSelected"></v-select>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Registration No. </label>
                    <input @blur="$v.fields.registration_number.$touch()" v-model="fields.registration_number" type="text" class="form-control" autofocus="">
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Estd. Date </label>
                    <input @blur="$v.fields.established_date.$touch()" v-model="fields.established_date" type="text" class="form-control" autofocus="">
                </div>
                <div class="form-group  col-md-4">
                    <label for="">PAN/VAT No. </label>
                    <input @blur="$v.fields.pan_vat_number.$touch()" v-model="fields.pan_vat_number" type="text" class="form-control" autofocus="">
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Address </label>
                    <input @blur="$v.fields.address.$touch()" v-model="fields.address" type="text" class="form-control" autofocus="">
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Phone No. </label>
                    <input @blur="$v.fields.mobile_landline_number.$touch()" v-model="fields.mobile_landline_number" type="text" class="form-control" autofocus="">
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Website</label>
                    <input @blur="$v.fields.website.$touch()" v-model="fields.website" type="text" class="form-control" autofocus="">
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Email </label>
                    <input @blur="$v.fields.email.$touch()" v-model="fields.email" type="text" class="form-control" autofocus="">
                </div>
                <div class="form-group  col-md-12">
                    <label for="">About Farm </label>
                    <textarea @blur="$v.fields.about_farm.$touch()" v-model="fields.about_farm" type="text" class="form-control" autofocus=""></textarea>
                </div>
                <div class="form-group  col-md-12">
                    <div class="r-m">
                        <label for="">Products</label>
                        <div class="input-repeat" v-for="(tag,k) in tags">
                            <input type="text" v-model="tag.name"> <span @click="removeTag(k)"><i class="fa fa-trash"></i></span>
                        </div>
                        <a class="add-more" @click="addTag()">Add More</a>
                    </div>
                </div>
                <div class="form-group  col-md-12 l-note">
                    <label for="">Leave Notes </label>
                    <textarea @blur="$v.fields.leave_note.$touch()" v-model="fields.leave_note" type="text" class="form-control" autofocus=""></textarea>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Farm Logo</label>
                    <div class="file-container">
                        <!-- <span @click="removeFarmLogoImage" v-if="fields.farm_logo_file!=null"><i class="fa fa-close"></i></span> -->
                        <div class="file-preview" @click="farmLogoInputTrigger">
                            <a href="javascript:void(0);"><i class="fa fa-image"></i></a>
                            <img v-if="fields.farm_logo_file!=null && fields.farm_logo_file!=''" :src="_farm_logo" class="image-view">
                        </div>
                        <input @change="handleFarmLogoSelected" type="file" ref="farmLogoInput" class="hide-element" accept="image/gif, image/jpeg, image/png">
                    </div>

                    <div class="image-action">
                        <a href="javascript:void(0)" @click="viewDocument(_farm_logo)"><i class="fa fa-eye"></i> &nbsp;View</a>
                        <a href=""><i class="fa fa-download"></i> &nbsp;Download</a>
                    </div>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Cover Image</label>
                    <div class="file-container">
                        <!-- <span @click="removeCoverImage" v-if="fields.cover_image_file!=null"><i class="fa fa-close"></i></span> -->
                        <div class="file-preview" @click="coverImageInputTrigger">
                            <a href="javascript:void(0);"><i class="fa fa-image"></i></a>
                            <img v-if="fields.cover_image_file!=null && fields.cover_image_file!=''" :src="_cover_image" class="image-view">
                        </div>
                        <input @change="handleCoverImageSelected" type="file" ref="coverImageInput" class="hide-element" accept="image/gif, image/jpeg, image/png">
                    </div>
                    <div class="image-action">
                        <a href=""><i class="fa fa-eye"></i> &nbsp;View</a>
                        <a href=""><i class="fa fa-download"></i> &nbsp;Download</a>
                    </div>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">नगरपालिकामा दर्ता गरेको फोटो</label>
                    <div class="file-container">
                        <!-- <span @click="removeRegistrationImage" v-if="fields.registration_image_file!=null"><i class="fa fa-close"></i></span> -->
                        <div class="file-preview" @click="registrationImageInputTrigger">
                            <a href="javascript:void(0);"><i class="fa fa-image"></i></a>
                            <img v-if="fields.registration_image_file!=null && fields.registration_image_file!=''" :src="_registration_image" class="image-view">
                        </div>
                        <input @change="handleRegistrationImageSelected" type="file" ref="registrationImageInput" class="hide-element" accept="image/gif, image/jpeg, image/png">
                    </div>
                    <div class="image-action">
                        <a href=""><i class="fa fa-eye"></i> &nbsp;View</a>
                        <a href=""><i class="fa fa-download"></i> &nbsp;Download</a>
                    </div>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">घरेलुमा दर्ता गरेको फोटो</label>
                    <div class="file-container">
                        <div class="file-preview" @click="homeRegistrationImageInputTrigger">
                            <a href="javascript:void(0);"><i class="fa fa-image"></i></a>
                            <img v-if="fields.home_registration_image_file!=null && fields.home_registration_image_file!=''" :src="_home_registration_image" class="image-view">
                        </div>
                        <input @change="handleHomeRegistrationImageSelected" type="file" ref="homeRegistrationImageInput" class="hide-element" accept="image/gif, image/jpeg, image/png">
                    </div>
                    <div class="image-action">
                        <a href=""><i class="fa fa-eye"></i> &nbsp;View</a>
                        <a href=""><i class="fa fa-download"></i> &nbsp;Download</a>
                    </div>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">PAN/VAT फोटो</label>
                    <div class="file-container">
                        <!-- <span @click="removePanVatImage" v-if="fields.pan_vat_image_file!=null"><i class="fa fa-close"></i></span> -->
                        <div class="file-preview" @click="panVatImageInputTrigger">
                            <a href="javascript:void(0);"><i class="fa fa-image"></i></a>
                            <img v-if="fields.pan_vat_image_file!=null && fields.pan_vat_image_file!=''" :src="_pan_vat_image" class="image-view">
                        </div>
                        <input @change="handlePanVatImageSelected" type="file" ref="panVatImageInput" class="hide-element" accept="image/gif, image/jpeg, image/png">
                    </div>
                    <div class="image-action">
                        <a href=""><i class="fa fa-eye"></i> &nbsp;View</a>
                        <a href=""><i class="fa fa-download"></i> &nbsp;Download</a>
                    </div>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Tax Clearance Image</label>
                    <div class="file-container">
                        <div class="file-preview" @click="taxClearanceImageInputTrigger">
                            <a href="javascript:void(0);"><i class="fa fa-image"></i></a>
                            <img v-if="fields.tax_clearance_image_file!=null && fields.tax_clearance_image_file!=''" :src="_tax_clearance_image" class="image-view">
                        </div>
                        <input @change="handleTaxClearanceImageSelected" type="file" ref="taxClearanceImageInput" class="hide-element" accept="image/gif, image/jpeg, image/png">
                    </div>
                    <div class="image-action">
                        <a href=""><i class="fa fa-eye"></i> &nbsp;View</a>
                        <a href=""><i class="fa fa-download"></i> &nbsp;Download</a>
                    </div>
                </div>
                <div class="form-group col-md-12 submit-container">
                    <button type="submit" class="btn agro-submit-btn">{{isEditing==true?'Update':'Submit'}} </button>
                    <div class="loader" v-if="is_loading">
                        <img class="logo" :src="require('@/assets/loader.gif')" alt="">
                    </div>
                </div>
            </form>
        </div>
    </main>
</template>
<script>
import { ref } from "vue";
import { required, email } from 'vuelidate/lib/validators';

export default {
    data() {
        return {
            id: this.$route.params.id,
            isEditing: false,
            is_loading: false,
            status: { id: 'not_verified', text: 'Not Verified' },
            status_touched: false,
            user: null,
            user_touched: false,
            tags: [],
            farm_logo: null,
            is_farm_logo_changed: false,
            cover_image: null,
            is_cover_image_changed: false,
            registration_image: null,
            is_registration_image_changed: false,
            home_registration_image: null,
            is_home_registration_image_changed: false,
            tax_clearance_image: null,
            is_tax_clearance_image_changed: false,
            pan_vat_image: null,
            is_pan_vat_image_changed: false,
            status_list: [{
                    id: 'not_verified',
                    text: 'Not Verified'
                },
                {
                    id: 'under_review',
                    text: 'Under Reveiw'
                },
                {
                    id: 'verified',
                    text: 'Verified'
                }
            ],
            fields: {
                farm_name: '',
                user_id: '',
                status: 'not_verified',
                registration_number: '',
                established_date: '',
                pan_vat_number: '',
                address: '',
                mobile_landline_number: '',
                website: '',
                email: '',
                about_farm: '',
                leave_note: '',
                farm_logo: '',
                farm_logo_file: '',
                cover_image: '',
                cover_image_file: '',
                registration_image: '',
                registration_image_file: '',
                pan_vat_image: '',
                pan_vat_image_file: '',
                home_registration_image: '',
                home_registration_image_file: '',
                tax_clearance_image: '',
                tax_clearance_image_file: ''
            }
        }
    },
    validations: {
        fields: {}
    },
    computed: {
        viewDocument(_url){
          
        },
        users() {
            return !this.$store.getters['user/usersOption'] ? {} : this.$store.getters['user/usersOption'];
        },
        _farm_logo() {
            if (this.is_farm_logo_changed)
                return this.fields.farm_logo_file;

            return this.$hostname + this.fields.farm_logo_file;
        },
        _cover_image() {
            if (this.is_cover_image_changed)
                return this.fields.cover_image_file;

            return this.$hostname + this.fields.cover_image_file;
        },
        _registration_image() {
            if (this.is_registration_image_changed)
                return this.fields.registration_image_file;

            return this.$hostname + this.fields.registration_image_file;
        },
        _home_registration_image() {
            if (this.is_home_registration_image_changed)
                return this.fields.home_registration_image_file;

            return this.$hostname + this.fields.home_registration_image_file;
        },
        _tax_clearance_image() {
            if (this.is_tax_clearance_image_changed)
                return this.fields.tax_clearance_image_file;

            return this.$hostname + this.fields.tax_clearance_image_file;
        },

        _pan_vat_image() {
            if (this.is_pan_vat_image_changed)
                return this.fields.pan_vat_image_file;

            return this.$hostname + this.fields.pan_vat_image_file;
        }

    },
    watch: {

        farm_logo(file, oldFile) {
            this.is_farm_logo_changed = true;
            if (!(file instanceof File)) return;
            let _this = this;
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.addEventListener('load', function() {
                _this.fields.farm_logo = fileReader.result;
                _this.fields.farm_logo_file = fileReader.result;
            })
        },
        cover_image(file, oldFile) {
            this.is_cover_image_changed = true;
            if (!(file instanceof File)) return;
            let _this = this;
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.addEventListener('load', function() {
                _this.fields.cover_image = fileReader.result;
                _this.fields.cover_image_file = fileReader.result;
            })
        },
        registration_image(file, oldFile) {
            this.is_registration_image_changed = true;
            if (!(file instanceof File)) return;
            let _this = this;
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.addEventListener('load', function() {
                _this.fields.registration_image = fileReader.result;
                _this.fields.registration_image_file = fileReader.result;
            })
        },
        home_registration_image(file, oldFile) {
            this.is_home_registration_image_changed = true;
            if (!(file instanceof File)) return;
            let _this = this;
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.addEventListener('load', function() {
                _this.fields.home_registration_image = fileReader.result;
                _this.fields.home_registration_image_file = fileReader.result;
            })
        },
        tax_clearance_image(file, oldFile) {
            this.is_tax_clearance_image_changed = true;
            if (!(file instanceof File)) return;
            let _this = this;
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.addEventListener('load', function() {
                _this.fields.tax_clearance_image = fileReader.result;
                _this.fields.tax_clearance_image_file = fileReader.result;
            })
        },
        pan_vat_image(file, oldFile) {
            this.is_pan_vat_image_changed = true;
            if (!(file instanceof File)) return;
            let _this = this;
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.addEventListener('load', function() {
                _this.fields.pan_vat_image = fileReader.result;
                _this.fields.pan_vat_image_file = fileReader.result;
            })
        }

    },
    methods: {
        addTag() {
            this.tags.push({ name: '' })

        },
        removeTag(key) {
            this.tags.splice(key, 1)
        },
        setSelected(value) {
            this.user = value;
            if (value != null)
                this.fields.user_id = value.id;
            console.log(this.fields);
            this.user_touched = true;
        },
        setStatusSelected(value) {
            this.status = value;
            if (value != null)
                this.fields.status = value.id;
            this.status_touched = true;
        },
        createFarm() {
            this.is_loading = true;
            this.status_touched = true;
            this.user_touched = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.is_loading = false;
                return;
            }
            let data = new FormData();
            data.append('farm_name', this.fields.farm_name);
            data.append('user_id', this.fields.user_id);
            data.append('status', this.fields.status);
            data.append('registration_number', this.fields.registration_number);
            data.append('established_date', this.fields.established_date);
            data.append('pan_vat_number', this.fields.pan_vat_number);
            data.append('address', this.fields.address);
            data.append('mobile_landline_number', this.fields.mobile_landline_number);
            data.append('website', this.fields.website);
            data.append('email', this.fields.email);
            data.append('about_farm', this.fields.about_farm);
            data.append('leave_note', this.fields.leave_note);
            data.append('tags', JSON.stringify(this.tags));
            data.append('farm_logo', this.farm_logo);
            data.append('cover_image', this.cover_image);
            data.append('registration_image', this.registration_image);
            data.append('pan_vat_image', this.pan_vat_image);
            data.append('home_registration_image', this.home_registration_image);
            data.append('tax_clearance_image', this.tax_clearance_image);


            console.log('form data', data);

            this.$store.dispatch('farm/storeFarm', data)
                .then(res => {
                    this.is_loading = false;
                    this.$router.replace('/farm')
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                    this.is_loading = false;

                });

        },
        updateFarm() {

            console.log('update data', this.fields)
            let _this = this;
            this.is_loading = true;
            this.user_touched = true;
            this.status_touched = true;
            this.$v.$touch();


            console.log('update ', this.fields);
            if (this.$v.$invalid) {
                this.is_loading = false;
                return;
            }
            let data = new FormData();
            data.append('farm_name', this.fields.farm_name);
            data.append('user_id', this.fields.user_id);
            data.append('status', this.fields.status);
            data.append('established_date', this.fields.established_date);
            data.append('registration_number', this.fields.registration_number);
            data.append('pan_vat_number', this.fields.pan_vat_number);
            data.append('address', this.fields.address);
            data.append('mobile_landline_number', this.fields.mobile_landline_number);
            data.append('website', this.fields.website);
            data.append('email', this.fields.email);
            data.append('about_farm', this.fields.about_farm);
            data.append('leave_note', this.fields.leave_note);
            data.append('tags', JSON.stringify(this.tags));
            data.append('farm_logo', this.farm_logo);
            data.append('cover_image', this.cover_image);
            data.append('registration_image', this.registration_image);
            data.append('pan_vat_image', this.pan_vat_image);
            data.append('home_registration_image', this.home_registration_image);
            data.append('tax_clearance_image', this.tax_clearance_image);

            this.$store.dispatch('farm/updateFarm', { id: this.id, formData: data })
                .then(res => {
                    this.is_loading = false;
                    this.$confirm({
                        title: 'Success',
                        message: 'Farm Successfully Updated.',
                        button: {
                            yes: 'OK',
                        },
                        callback: confirm => {
                            if (confirm) {
                                this.$router.replace('/farm')
                            }
                        }
                    })

                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                    this.is_loading = false;

                });

        },

        /**
         * farm Logo
         */
        handleFarmLogoSelected(event) {
            console.log('event', event);
            if (event.target.files.length == 0) {
                this.farm_logo = null;
                this.fields.farm_logo = null;
                return;
            }
            this.farm_logo = event.target.files[0];
        },

        removeFarmLogoImage() {
            this.farm_logo = null;
            this.fields.farm_logo = null;
            this.fields.farm_logo_file = null;
        },
        farmLogoInputTrigger() {
            this.$refs.farmLogoInput.click();
        },

        /**
         * farm Logo Ends
         */

        /**
         * cover image
         */
        handleCoverImageSelected(event) {
            console.log('event', event);
            if (event.target.files.length == 0) {
                this.cover_image = null;
                this.fields.cover_image = null;
                return;
            }
            this.cover_image = event.target.files[0];
        },

        removeCoverImage() {
            this.cover_image = null;
            this.fields.cover_image = null;
            this.fields.cover_image_file = null;
        },
        coverImageInputTrigger() {
            this.$refs.coverImageInput.click();
        },

        /**
         * cover image Ends
         */

        /**
         * registration image
         */
        handleRegistrationImageSelected(event) {
            console.log('event', event);
            if (event.target.files.length == 0) {
                this.registration_image = null;
                this.fields.registration_image = null;
                return;
            }
            this.registration_image = event.target.files[0];
        },

        removeRegistrationImage() {
            this.registration_image = null;
            this.fields.registration_image = null;
            this.fields.registration_image_file = null;
        },
        registrationImageInputTrigger() {
            this.$refs.registrationImageInput.click();
        },
        /**
         * pan vat image
         */
        handlePanVatImageSelected(event) {
            console.log('event', event);
            if (event.target.files.length == 0) {
                this.pan_vat_image = null;
                this.fields.pan_vat_image = null;
                return;
            }
            this.pan_vat_image = event.target.files[0];
        },

        removePanVatImage() {
            this.pan_vat_image = null;
            this.fields.pan_vat_image = null;
            this.fields.pan_vat_image_file = null;
        },
        panVatImageInputTrigger() {
            this.$refs.panVatImageInput.click();
        },

        /**
         * pan vat image Ends
         */





        /**
         * home registration Image
         */
        handleHomeRegistrationImageSelected(event) {
            console.log('event', event);
            if (event.target.files.length == 0) {
                this.home_registration_image = null;
                this.fields.home_registration_image = null;
                return;
            }
            this.home_registration_image = event.target.files[0];
        },

        removeHomeRegistrationImage() {
            this.home_registration_image = null;
            this.fields.home_registration_image = null;
            this.fields.home_registration_image_file = null;
        },
        homeRegistrationImageInputTrigger() {
            this.$refs.homeRegistrationImageInput.click();
        },



        /**
         * tax clearance Image
         */

        handleTaxClearanceImageSelected(event) {
            console.log('event', event);
            if (event.target.files.length == 0) {
                this.tax_clearance_image = null;
                this.fields.tax_clearance_image = null;
                return;
            }
            this.tax_clearance_image = event.target.files[0];
        },
        removeTaxClearanceImage() {
            this.tax_clearance_image = null;
            this.fields.tax_clearance_image = null;
            this.fields.tax_clearance_image_file = null;
        },
        taxClearanceImageInputTrigger() {
            this.$refs.taxClearanceImageInput.click();
        },


        checkNull(str) {
            return str == null ? '' : str;
        },


        /**
         * tax clearance Image  ends
         */

        getAndSetData() {
            // console.log(this.id);
            let _this = this;
            this.$store.dispatch('farm/editFarm', this.id).then((res) => {
                this.fields.farm_name = _this.checkNull(res.data.farm_name);
                this.fields.user_id = res.data.user_id;
                this.fields.status = _this.checkNull(res.data.status);
                this.fields.registration_number = _this.checkNull(res.data.registration_number);
                this.fields.pan_vat_number = _this.checkNull(res.data.pan_vat_number);
                this.fields.address = _this.checkNull(res.data.address);
                this.fields.mobile_landline_number = _this.checkNull(res.data.mobile_landline_number);
                this.fields.website = _this.checkNull(res.data.website);
                this.fields.email = _this.checkNull(res.data.email);
                this.fields.about_farm = _this.checkNull(res.data.about_farm);
                this.fields.leave_note = _this.checkNull(res.data.leave_note);
                this.fields.established_date = _this.checkNull(res.data.established_date);

                // logo setup
                this.fields.farm_logo = _this.checkNull(res.data.farm_logo);
                this.fields.farm_logo_file = _this.checkNull(res.data.farm_logo_file);

                // cover image setup
                this.fields.cover_image = _this.checkNull(res.data.cover_image);
                this.fields.cover_image_file = _this.checkNull(res.data.cover_image_file);

                // registration image setup
                this.fields.registration_image = _this.checkNull(res.data.registration_image);
                this.fields.registration_image_file = _this.checkNull(res.data.registration_image_file);

                // pan_vat image setup
                this.fields.pan_vat_image = _this.checkNull(res.data.pan_vat_image);
                this.fields.pan_vat_image_file = _this.checkNull(res.data.pan_vat_image_file);

                // home_registration image setup
                this.fields.home_registration_image = _this.checkNull(res.data.home_registration_image);
                this.fields.home_registration_image_file = _this.checkNull(res.data.home_registration_image_file);

                // tax_clearance image setup
                this.fields.tax_clearance_image = _this.checkNull(res.data.tax_clearance_image);
                this.fields.tax_clearance_image_file = _this.checkNull(res.data.tax_clearance_image_file);


                if (res.data.tags != null)
                    this.tags = JSON.parse(res.data.tags);

                this.user = {
                    id: res.data.user_id,
                    text: res.data.user_name
                };

                this.status = {
                    id: res.data.status,
                    text: res.data.status
                };


            })
        }
    },
    mounted() {
        let id = this.$route.params.id
        this.isEditing = (id === undefined) ? false : true;
        if (this.isEditing)
            this.getAndSetData();

    }
}
</script>
<style>
.image-action a {
    padding: 5px 10px;
    display: flex;
    background: #ffffff;
    align-items: center;
    width: 100%;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #3db47f;
    margin-right: 10px;
    margin-left: 10px;
    text-decoration:none;
    color: #3db47f;
}
.image-action a:hover{
  text-decoration:none;
  color:white;
  background: #3db47f;
}
.image-action {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    margin-bottom: 30px;
}

.l-note textarea {
    background: #FFDBDB;
    border: 1px solid #F95668;
}

.select.invalid input {
    border: 1px solid white !important;
}

.select.invalid .v-select.vs--single.vs--searchable {
    border: 1px solid red;
    border-radius: 6px;
}

.input-repeat {
    margin: 10px 0;
    display: flex;
}

.input-repeat input {
    width: 50%;
    border: 1px solid #cdcdcd;
    padding: 6px;
    border-radius: 5px;
}

.input-repeat span {
    display: flex;
    margin-left: 10px;
    background: red;
    cursor: pointer;
    width: 35px;
    height: 35px;
    text-align: center;
    align-items: center;
    color: white;
    border-radius: 99px;
    justify-content: center;
}

.input-repeat span:hover {
    background: #3db47f;
}

.add-more {
    padding: 6px 10px;
    background: #29d;
    color: white !important;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    display: inline-block;
}

.r-m {
    background: #f3f6f7;
    padding: 25px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.r-m label {
    display: block;
}
</style>