import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/store'
import axios from './axios/auth-instance'

import NProgress from 'nprogress'

import Login from './views/auth/login/Login.vue'
import Dashboard from './views/dashboard/Dashboard.vue'
/**
 * user Module
 */
import User from './views/pages/user/User.vue'
import UserList from './views/pages/user/UserList.vue'
import UserForm from './views/pages/user/UserForm.vue'
import ChangePasswordForm from './views/pages/user/ChangePasswordForm.vue'

/**
 * application module
 */
import Application from './views/pages/application/Application.vue'
import ApplicationList from './views/pages/application/ApplicationList.vue'
import ApplicationForm from './views/pages/application/ApplicationForm.vue'

/**
 * category module
 */
import Category from './views/pages/category/Category.vue'
import CategoryList from './views/pages/category/CategoryList.vue'
import CategoryForm from './views/pages/category/CategoryForm.vue'

/**
 * Blog module
 */
import Blog from './views/pages/blog/Blog.vue'
import BlogList from './views/pages/blog/BlogList.vue'
import BlogForm from './views/pages/blog/BlogForm.vue'


/**
 * Program module
 */
import Program from './views/pages/program/Program.vue'
import ProgramList from './views/pages/program/ProgramList.vue'
import ProgramForm from './views/pages/program/ProgramForm.vue'

/**
 * Slider module
 */
import Slider from './views/pages/slider/Slider.vue'
import SliderList from './views/pages/slider/SliderList.vue'
import SliderForm from './views/pages/slider/SliderForm.vue'

/**
 * farm Module
 */
import Farm from './views/pages/farm/Farm.vue'
import FarmList from './views/pages/farm/FarmList.vue'
import FarmForm from './views/pages/farm/FarmForm.vue'
import FarmShow from './views/pages/farm/FarmShow.vue'


NProgress.configure({ easing: 'ease', speed: 500 });
Vue.use(VueRouter)

const routes = [
  { path: '/',name:'login', component: Login ,meta:{layout:'LoginLayout'}},
  { path: '/dashboard', name:'dashboard', component: Dashboard, meta:{layout:'MainLayout'}},
  { path: '/user',name:'user', component: User ,meta:{layout:'MainLayout'},
  	children:[
       { path: '',name:'user-list', component: UserList ,meta:{layout:'MainLayout'}},
  		 { path: 'create',name:'user-create', component: UserForm ,meta:{layout:'MainLayout'}},
         { path: ':id/edit',name:'user-edit', component: UserForm ,meta:{layout:'MainLayout'}},
         { path: ':id/change-password',name:'user-change-password', component: ChangePasswordForm ,meta:{layout:'MainLayout'}}
  	]
  },
  { path: '/application',name:'application', component: Application ,meta:{layout:'MainLayout'},
  	children:[
       { path: '',name:'application-list', component: ApplicationList ,meta:{layout:'MainLayout'}},
  		 { path: 'create',name:'application-create', component: ApplicationForm ,meta:{layout:'MainLayout'}},
  		 { path: ':id/edit',name:'application-edit', component: ApplicationForm ,meta:{layout:'MainLayout'}}
  	]

  },
  { path: '/category',name:'category', component: Category ,meta:{layout:'MainLayout'},
  	children:[
       { path: '',name:'category-list', component: CategoryList ,meta:{layout:'MainLayout'}},
  		 { path: 'create',name:'category-create', component: CategoryForm ,meta:{layout:'MainLayout'}},
  		 { path: ':id/edit',name:'category-edit', component: CategoryForm ,meta:{layout:'MainLayout'}}
  	]

  },
  { path: '/blog',name:'blog', component: Blog ,meta:{layout:'MainLayout'},
     children:[
       { path: '',name:'blog-list', component: BlogList ,meta:{layout:'MainLayout'}},
           { path: 'create',name:'blog-create', component: BlogForm ,meta:{layout:'MainLayout'}},
           { path: ':id/edit',name:'blog-edit', component: BlogForm ,meta:{layout:'MainLayout'}}
     ]

  },   
  { path: '/program',name:'program', component: Program ,meta:{layout:'MainLayout'},
     children:[
       { path: '',name:'program-list', component: ProgramList ,meta:{layout:'MainLayout'}},
           { path: 'create',name:'program-create', component: ProgramForm ,meta:{layout:'MainLayout'}},
           { path: ':id/edit',name:'program-edit', component: ProgramForm ,meta:{layout:'MainLayout'}}
     ]

  },   
  { path: '/slider',name:'slider', component: Slider ,meta:{layout:'MainLayout'},
     children:[
       { path: '',name:'slider-list', component: SliderList ,meta:{layout:'MainLayout'}},
           { path: 'create',name:'slider-create', component: SliderForm ,meta:{layout:'MainLayout'}},
           { path: ':id/edit',name:'slider-edit', component: SliderForm ,meta:{layout:'MainLayout'}}
     ]

  },  
  { path: '/farm',name:'farm', component: Farm ,meta:{layout:'MainLayout'},
    children:[
       { path: '',name:'farm-list', component: FarmList ,meta:{layout:'MainLayout'}},
         { path: 'create',name:'farm-create', component: FarmForm ,meta:{layout:'MainLayout'}},
         { path: ':id/edit',name:'farm-edit', component: FarmForm ,meta:{layout:'MainLayout'}},
         { path: ':id/show',name:'farm-show', component: FarmShow ,meta:{layout:'MainLayout'}}
    ]

  },

  { path: '/logout',name:'logout' ,meta:{layout:'MainLayout'}},
]

const router =  new VueRouter({mode: 'history', routes});

router.beforeEach((to, from, next) => {
     // store.dispatch('common/toggleNavMenu');
     
     console.log('path',to.path);
	 NProgress.start();
		if(to.path==='/'){
			store.state.auth.token?next('/dashboard'):next();
		}else{
			if(!store.state.auth.token){
				next('/');
			}
			let token = store.state.auth.token;
			let token_type = 'Bearer';
			axios.defaults.headers.common['Authorization'] = `${token_type} ${token}`
			next();
  	    }
})
router.afterEach(()=>{
  NProgress.done();
})

export default router;