import router from '@/router'
import axios from '@/axios/auth-instance'
export default {

  namespaced:true,
  state: {
      user_id:null,
  	  token:null,
  	  expires_in:null,
      role:null,
      name:null,
      email:null,
      serverError:false,
  },
  mutations: {
      error(state,data){
          state.serverError = true;
      },
      auth(state,data){

        state.user_id = data.user_id
        state.token = data.token
        state.expires_in = data.expires_in
        state.role = data.role
        state.name = data.name
        state.email = data.email
      },
      clearAuth(state){
          state.user_id = null
          state.token = null
          state.expires_in = null
          state.role = null
          state.name = null
          state.email = null
      }
  },
  actions: {
      tryAutoLogin({commit,dispatch}){
        const now = new Date();
        const user_id = localStorage.getItem('user_id');
        const token = localStorage.getItem('token');
        const expirationDate = new Date(localStorage.getItem('expires_in'));
        const role = localStorage.getItem('role');
        const name = localStorage.getItem('name');  
        const email = localStorage.getItem('email');

        if(!token){
          localStorage.clear();
          return false;
        }

        if (now>=expirationDate) {
          dispatch('logout');
          return
        }
       
        let authData = {
          user_id:user_id,
          token:token,
          expires_in:expirationDate,
          role:role,
          name:name,
          email:email
        };  

        // console.log('localStorage',authData);
        commit('auth',authData)
        //auto logout to be implement from localstorage data
        router.replace('/dashboard')

      },
      setLogoutTimer({commit,dispatch},expireLength){
        setTimeout(function(){
          dispatch('logout');
        },expireLength*1000)
      },
  		login({commit,dispatch},authData){
        return axios.post('/login',authData)
                     .then(res=> {
                       dispatch('setLogoutTimer',res.data.expire_length);
                       commit('auth',res.data);
                      
                       const now = new Date();
                       const expirationDate = new Date(now.getTime()+(res.data.expires_in*1000));
                       localStorage.setItem('user_id',res.data.user_id);
                       localStorage.setItem('token',res.data.token);
                       localStorage.setItem('expires_in',res.data.expires_in);
                       localStorage.setItem('role',res.data.role);
                       localStorage.setItem('name',res.data.name);
                       localStorage.setItem('email',res.data.email);
                       router.replace('/dashboard')

                     });
                    
  		},
      clientLogout({commit}){
           commit('clearAuth');
           localStorage.removeItem('user_id');
           localStorage.removeItem('token');
           localStorage.removeItem('expires_in');
           localStorage.removeItem('role');
           localStorage.removeItem('name');
           localStorage.removeItem('email');
           delete axios.defaults.headers.common['Authorization'];
           router.replace('/')
      },
      logout({commit}){
          axios.post('/logout')
             .then(res=> {
                 commit('clearAuth');
                 localStorage.removeItem('user_id');
                 localStorage.removeItem('token');
                 localStorage.removeItem('expires_in');
                 localStorage.removeItem('role');
                 localStorage.removeItem('name');
                 localStorage.removeItem('email');
                 delete axios.defaults.headers.common['Authorization'];
                 router.replace('/')
            })
             .catch(error=>console.log(error))
      },
      

  },
  getters: {
    isAuthenticated (state){
      return state.token !== null
    },
    serverError(state){
      return state.serverError
    },
    auth(state){
      return state;
    }

  }
};

