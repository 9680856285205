import router from '@/router'
import axios from '@/axios/auth-instance'
export default {

  namespaced:true,
  state: {
      applications:null
  },
  mutations: {
   
      setApplication(state,applications){
        state.applications = applications;
      }
  },
  actions: {
  		getApplications({commit,dispatch},payload){
        axios.get('/applications?page='+payload.page+'&&q='+payload.key)
             .then(res=> {
                commit('setApplication',res.data);
             })
             .catch(error=>console.log(error))
  		},
      storeApplication({commint,dispatch},payload){
        return axios.post('/application/store',payload)
      },
      editApplication({commit,dispatch},id){
       return axios.get('/application/'+id+'/edit')
             .then(res=> {
               return res;
             })
             .catch(error=>console.log(error))
      },
      updateApplication({commit,dispatch},payload){
        return axios.post('/application/'+payload.id+'/update',payload.formData)
            
      },
      deleteApplication({commit,dispatch},id){
        return  axios.get('/application/'+id+'/delete')
            
      },
  },
  getters: {
    applications(state){
      return state.applications;
    }
  }
};

