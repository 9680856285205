<template>
	<main class="agro-main ">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/dashboard"><i class="fa fa-home"></i>  Home</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
        </ol>


        <div class="row dash-box animate__animated animate__fadeIn">
          <div class="col-md-3 db">
            <div class="dash-card">
              <div class="dash-card-inner-wrapper">
                  <div class="dash-content">
                    <h2><i class="fa fa-bar-chart-o"></i> Farms</h2>
                    <p>Total Farms</p>
                  </div>
                  <div class="dash-count">
                    <h2>{{farm_total}}</h2>
                  </div>
              </div>
              <div class="go-btn">
                <a href="javascript:void(0)" @click="navigatePage('/farm')">View &nbsp; <i class="fa fa-long-arrow-right"></i></a>
              </div>
            </div>

          </div>
          <div class="col-md-3 db">
           <div class="dash-card">
              <div class="dash-card-inner-wrapper">
                <div class="dash-content">
                  <h2><i class="fa fa-envelope-o"></i> Programs</h2>
                  <p>Total Programs</p>
                </div>
                <div class="dash-count">
                  <h2>{{program_total}}</h2>
                </div>
              </div>
              <div class="go-btn">
                <a href="javascript:void(0)" @click="navigatePage('/program')">View &nbsp; <i class="fa fa-long-arrow-right"></i></a>
              </div>
            </div>
          </div>
          <div class="col-md-3 db">
           <div class="dash-card">
              <div class="dash-card-inner-wrapper">
                <div class="dash-content">
                  <h2><i class="fa fa-wpforms"></i> Crops Infection</h2>
                  <p>Total Blogs</p>
                 </div>
                <div class="dash-count">
                  <h2>{{blog_total}}</h2>
                </div>
               </div>
              <div class="go-btn">
                <a href="javascript:void(0)" @click="navigatePage('/blog')">View &nbsp; <i class="fa fa-long-arrow-right"></i></a>
              </div>
            </div>
          </div>
          <div class="col-md-3 db">
           <div class="dash-card">
              <div class="dash-card-inner-wrapper">
                 <div class="dash-content">
                  <h2><i class="fa fa-address-book-o"></i> Users</h2>
                  <p>Total Users</p>
                 </div>
                <div class="dash-count">
                  <h2>{{user_total}}</h2>
                </div>
               </div>
              <div class="go-btn">
                <a href="javascript:void(0)" @click="navigatePage('/user')">View &nbsp; <i class="fa fa-long-arrow-right"></i></a>
              </div>
            </div>
          </div>
         
        </div>
        <div class="row  animate__animated animate__fadeIn">
          <div class="col-md-6">
            <div class="chart-content">
               <h2>Application Status </h2>
               <div class="loader" v-if="!chartLoaded">
                  <img class="logo" :src="require('@/assets/loader.gif')" alt="">
               </div>
               <canvas id="myChart" width="100%" ></canvas>
            </div>
          </div>
          <div class="col-md-6">
              <div class="application-list" style="margin:10px 0 0 0">
                <h2><i class="fa fa-wpforms"></i> Applications (Under Development)</h2>
                <p class="no-app" v-if="applications.total==0">No Application Submitted Yet.</p>
                <ul class="apps">
                  <li>
                    <a class="application">
                      <h3>Nirajan Rawal</h3>
                      <span><i class="fa fa-long-arrow-right"></i></span>
                    </a>
                  </li>
                </ul>
                <a href="javascript:void(0)" @click="navigatePage('application')" class="app-more">View More</a>
              </div>
          </div>
        </div>

  </main>
</template>
<script>
  export default {
    data () {
      return {
        data: [],
        farm_total:0,
        program_total:0,
        blog_total:0,
        user_total:0,
        chartLoaded:false,

      }
    },
    computed:{
      email(){
        return !this.$store.getters['auth/user'].email?false:this.$store.getters['auth/user'].email
      },
      reports(){
        return !this.$store.getters['dashboard/getReports']?{}:this.$store.getters['dashboard/getReports'];
      }, 
      applications(){
        return !this.$store.getters['application/applications']?{}:this.$store.getters['application/applications'];
      },
    },
    watch:{
      reports(){
        this.loadReport();
      }
    },
    methods:{
      navigatePage(url){
         this.$router.push(url);
      },
      loadChart(){

        var ctx = document.getElementById("myChart");
        var myChart = new Chart(ctx, {
            type: 'bar',
            data: {
              labels: ["Farms", "Programs", "Crop Blogs", "Users","Applications"],
              datasets: [{
                data: this.data,
                borderWidth:2,
                borderColor:["#c3e6cb","#ffefba","#bfe5eb","#b9daff","#f5c6ca"],
                backgroundColor:["#d4edd9","#fff3cd","#d1ebf1","#cce5fe","#f8d7d9"],
              }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                legend: {
                  display: false
               },
            }
          });
          this.chartLoaded = true;
        },
        loadReport(){
            this.data = [];
            let farm_total     = this.reports.data.farm_total;
            let program_total  = this.reports.data.program_total;
            let blog_total     = this.reports.data.blog_total;
            let user_total     = this.reports.data.user_total;
            this.data.push(farm_total);
            this.data.push(program_total);
            this.data.push(blog_total);
            this.data.push(user_total);

            this.farm_total     = farm_total;
            this.program_total  = program_total;
            this.blog_total     = blog_total;
            this.user_total     = user_total;

        }
    },
    mounted(){
        let _this = this;
        this.$store.dispatch('application/getApplications',{page:1,key:''});
        this.$store.dispatch('dashboard/loadReports').then(()=>{
            setTimeout(function(){
              _this.loadReport();
              _this.loadChart();
            },1000)
        });
      
    }
  }

</script>
<style type="text/css" src ="@/assets/css/component-wise/dashboard.css"></style>
