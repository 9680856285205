<template>
    <main class="agro-main">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <router-link to="/dashboard"><i class="fa fa-home"></i> Home</router-link>
            </li>
            <li class="breadcrumb-item">
                <router-link to="/farm">Farms</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Farm Details</li>
        </ol>
        <div class="agro-content agro-form">
            <h3> <span>FARM Details</span></h3>
            <form class="row farm-form">
                <div class="form-group col-md-4  select">
                    <label for="">Status </label>
                    <input  v-model="status.text" type="text" class="form-control" readonly>
                </div>
                <div class="form-group col-md-4">
                    <label for="farm_name">Farm Name </label>
                    <input  v-model="fields.farm_name" type="text" class="form-control" readonly>
                </div>
                <div class="form-group col-md-4 select">
                    <label for="">User </label>
                    <input  v-model="user.name" type="text" class="form-control" readonly>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Registration No. </label>
                    <input v-model="fields.registration_number" type="text" class="form-control" readonly>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Estd. Date </label>
                    <input v-model="fields.established_date" type="text" class="form-control" readonly>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">PAN/VAT No. </label>
                    <input v-model="fields.pan_vat_number" type="text" class="form-control" readonly>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Address </label>
                    <input v-model="fields.address" type="text" class="form-control" readonly>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Phone No. </label>
                    <input v-model="fields.mobile_landline_number" type="text" class="form-control" readonly>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Website</label>
                    <input v-model="fields.website" type="text" class="form-control" readonly>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Email </label>
                    <input v-model="fields.email" type="text" class="form-control" readonly>
                </div>
                <div class="form-group  col-md-12">
                    <label for="">About Farm </label>
                    <textarea v-model="fields.about_farm" type="text" class="form-control" readonly></textarea>
                </div>
                <div class="form-group  col-md-12">
                    <div class="r-m">
                        <label for="">Products</label>
                        <div class="input-repeat" v-for="(tag,k) in tags">
                            <input type="text" v-model="tag.name">
                        </div>
                    </div>
                </div>
                <div class="form-group  col-md-12 l-note">
                    <label for="">Leave Notes </label>
                    <textarea v-model="fields.leave_note" type="text" class="form-control" readonly></textarea>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Farm Logo</label>
                    <div class="file-container">
                        <div class="file-preview">
                            <img v-if="fields.farm_logo_file!=null && fields.farm_logo_file!=''" :src="_farm_logo" class="image-view">
                        </div>
                    </div>

                    <div class="image-action">
                        <a href="javascript:void(0)" @click="viewDocument(_farm_logo)"><i class="fa fa-eye"></i> &nbsp;View</a>
                        <a href=""><i class="fa fa-download"></i> &nbsp;Download</a>
                    </div>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Cover Image</label>
                    <div class="file-container">
                        <div class="file-preview" >
                            <img v-if="fields.cover_image_file!=null && fields.cover_image_file!=''" :src="_cover_image" class="image-view">
                        </div>
                    </div>
                    <div class="image-action">
                        <a href=""><i class="fa fa-eye"></i> &nbsp;View</a>
                        <a href=""><i class="fa fa-download"></i> &nbsp;Download</a>
                    </div>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">नगरपालिकामा दर्ता गरेको फोटो</label>
                    <div class="file-container">
                        <div class="file-preview">
                            <img v-if="fields.registration_image_file!=null && fields.registration_image_file!=''" :src="_registration_image" class="image-view">
                        </div>
                    </div>
                    <div class="image-action">
                        <a href=""><i class="fa fa-eye"></i> &nbsp;View</a>
                        <a href=""><i class="fa fa-download"></i> &nbsp;Download</a>
                    </div>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">घरेलुमा दर्ता गरेको फोटो</label>
                    <div class="file-container">
                        <div class="file-preview">
                            <img v-if="fields.home_registration_image_file!=null && fields.home_registration_image_file!=''" :src="_home_registration_image" class="image-view">
                        </div>
                    </div>
                    <div class="image-action">
                        <a href=""><i class="fa fa-eye"></i> &nbsp;View</a>
                        <a href=""><i class="fa fa-download"></i> &nbsp;Download</a>
                    </div>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">PAN/VAT फोटो</label>
                    <div class="file-container">
                        <div class="file-preview">
                            <img v-if="fields.pan_vat_image_file!=null && fields.pan_vat_image_file!=''" :src="_pan_vat_image" class="image-view">
                        </div>
                    </div>
                    <div class="image-action">
                        <a href=""><i class="fa fa-eye"></i> &nbsp;View</a>
                        <a href=""><i class="fa fa-download"></i> &nbsp;Download</a>
                    </div>
                </div>
                <div class="form-group  col-md-4">
                    <label for="">Tax Clearance Image</label>
                    <div class="file-container">
                        <div class="file-preview">
                            <img v-if="fields.tax_clearance_image_file!=null && fields.tax_clearance_image_file!=''" :src="_tax_clearance_image" class="image-view">
                        </div>
                    </div>
                    <div class="image-action">
                        <a href=""><i class="fa fa-eye"></i> &nbsp;View</a>
                        <a href=""><i class="fa fa-download"></i> &nbsp;Download</a>
                    </div>
                </div>
            </form>
        </div>
    </main>
</template>
<script>
import { ref } from "vue";
import { required, email } from 'vuelidate/lib/validators';

export default {
    data() {
        return {
            id: this.$route.params.id,
            is_loading: false,
            status: { id: 'not_verified', text: 'Not Verified' },
            status_touched: false,
            user: null,
            tags: [],
            farm_logo: null,
            cover_image: null,
            registration_image: null,
            home_registration_image: null,
            tax_clearance_image: null,
            pan_vat_image: null,
            status_list: [{
                    id: 'not_verified',
                    text: 'Not Verified'
                },
                {
                    id: 'under_review',
                    text: 'Under Reveiw'
                },
                {
                    id: 'verified',
                    text: 'Verified'
                }
            ],
            fields: {
                farm_name: '',
                user_id: '',
                status: 'not_verified',
                registration_number: '',
                established_date: '',
                pan_vat_number: '',
                address: '',
                mobile_landline_number: '',
                website: '',
                email: '',
                about_farm: '',
                leave_note: '',
                farm_logo: '',
                farm_logo_file: '',
                cover_image: '',
                cover_image_file: '',
                registration_image: '',
                registration_image_file: '',
                pan_vat_image: '',
                pan_vat_image_file: '',
                home_registration_image: '',
                home_registration_image_file: '',
                tax_clearance_image: '',
                tax_clearance_image_file: ''
            }
        }
    },
    validations: {
        fields: {}
    },
    computed: {
        viewDocument(_url){
          
        },
        users() {
            return !this.$store.getters['user/usersOption'] ? {} : this.$store.getters['user/usersOption'];
        },
        _farm_logo() {
            return this.$hostname + this.fields.farm_logo_file;
        },
        _cover_image() {
            return this.$hostname + this.fields.cover_image_file;
        },
        _registration_image() {
            return this.$hostname + this.fields.registration_image_file;
        },
        _home_registration_image() {
            return this.$hostname + this.fields.home_registration_image_file;
        },
        _tax_clearance_image() {
            return this.$hostname + this.fields.tax_clearance_image_file;
        },

        _pan_vat_image() {
            return this.$hostname + this.fields.pan_vat_image_file;
        }

    },
    watch: {


    },
    methods: {
  
        checkNull(str) {
            return str == null ? '' : str;
        },


        /**
         * tax clearance Image  ends
         */

        getAndSetData() {
            // console.log(this.id);
            let _this = this;
            this.$store.dispatch('farm/editFarm', this.id).then((res) => {
                this.fields.farm_name = _this.checkNull(res.data.farm_name);
                this.fields.user_id = res.data.user_id;
                this.fields.status = _this.checkNull(res.data.status);
                this.fields.registration_number = _this.checkNull(res.data.registration_number);
                this.fields.pan_vat_number = _this.checkNull(res.data.pan_vat_number);
                this.fields.address = _this.checkNull(res.data.address);
                this.fields.mobile_landline_number = _this.checkNull(res.data.mobile_landline_number);
                this.fields.website = _this.checkNull(res.data.website);
                this.fields.email = _this.checkNull(res.data.email);
                this.fields.about_farm = _this.checkNull(res.data.about_farm);
                this.fields.leave_note = _this.checkNull(res.data.leave_note);
                this.fields.established_date = _this.checkNull(res.data.established_date);

                // logo setup
                this.fields.farm_logo = _this.checkNull(res.data.farm_logo);
                this.fields.farm_logo_file = _this.checkNull(res.data.farm_logo_file);

                // cover image setup
                this.fields.cover_image = _this.checkNull(res.data.cover_image);
                this.fields.cover_image_file = _this.checkNull(res.data.cover_image_file);

                // registration image setup
                this.fields.registration_image = _this.checkNull(res.data.registration_image);
                this.fields.registration_image_file = _this.checkNull(res.data.registration_image_file);

                // pan_vat image setup
                this.fields.pan_vat_image = _this.checkNull(res.data.pan_vat_image);
                this.fields.pan_vat_image_file = _this.checkNull(res.data.pan_vat_image_file);

                // home_registration image setup
                this.fields.home_registration_image = _this.checkNull(res.data.home_registration_image);
                this.fields.home_registration_image_file = _this.checkNull(res.data.home_registration_image_file);

                // tax_clearance image setup
                this.fields.tax_clearance_image = _this.checkNull(res.data.tax_clearance_image);
                this.fields.tax_clearance_image_file = _this.checkNull(res.data.tax_clearance_image_file);


                if (res.data.tags != null)
                    this.tags = JSON.parse(res.data.tags);

                this.user = {
                    id: res.data.user_id,
                    text: res.data.user_name
                };

                this.status = {
                    id: res.data.status,
                    text: res.data.status
                };


            })
        }
    },
    mounted() {
        let id = this.$route.params.id
    
        this.getAndSetData();

    }
}
</script>
<style>
.image-action a {
    padding: 5px 10px;
    display: flex;
    background: #ffffff;
    align-items: center;
    width: 100%;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #3db47f;
    margin-right: 10px;
    margin-left: 10px;
    text-decoration:none;
    color: #3db47f;
}
.image-action a:hover{
  text-decoration:none;
  color:white;
  background: #3db47f;
}
.image-action {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    margin-bottom: 30px;
}

.l-note textarea {
    background: #FFDBDB;
    border: 1px solid #F95668;
}

.select.invalid input {
    border: 1px solid white !important;
}

.select.invalid .v-select.vs--single.vs--searchable {
    border: 1px solid red;
    border-radius: 6px;
}

.input-repeat {
    margin: 10px 0;
    display: flex;
}

.input-repeat input {
    width: 50%;
    border: 1px solid #cdcdcd;
    padding: 6px;
    border-radius: 5px;
}

.input-repeat span {
    display: flex;
    margin-left: 10px;
    background: red;
    cursor: pointer;
    width: 35px;
    height: 35px;
    text-align: center;
    align-items: center;
    color: white;
    border-radius: 99px;
    justify-content: center;
}

.input-repeat span:hover {
    background: #3db47f;
}

.add-more {
    padding: 6px 10px;
    background: #29d;
    color: white !important;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    display: inline-block;
}

.r-m {
    background: #f3f6f7;
    padding: 25px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.r-m label {
    display: block;
}
</style>