<template>
  <div id="app">
    <vue-confirm-dialog></vue-confirm-dialog>
     <vue-extend-layouts path="layouts"/>
  </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout'
export default {
  name: 'App',
  components: {
    VueExtendLayouts
  },
  created(){
  	this.$store.dispatch('auth/tryAutoLogin');
  }
}
</script>

<style>

</style>
