import router from '@/router'
import axios from '@/axios/auth-instance'
export default {

  namespaced:true,
  state: {
      categories:null,
      categoriesOptions:null
  },
  mutations: {
   
      setCategory(state,categories){
        state.categories = categories;
      },
      setCategoryOption(state,categories){
        state.categoriesOptions = categories;
      }
  },
  actions: {
      getAllCategories({commit,dispatch}){
          axios.get('/all-categories')
             .then(res=> {
                commit('setCategoryOption',res.data);
             })
             .catch(error=>console.log(error))
      },
  		getCategories({commit,dispatch},payload){
        axios.get('/categories?page='+payload.page+'&&q='+payload.key)
             .then(res=> {
                commit('setCategory',res.data);
             })
             .catch(error=>console.log(error))
  		},
      storeCategory({commint,dispatch},payload){
        return axios.post('/category/store',payload)
      },
      editCategory({commit,dispatch},id){
       return axios.get('/category/'+id+'/edit')
             .then(res=> {
               return res;
             })
             .catch(error=>console.log(error))
      },
      updateCategory({commit,dispatch},payload){
        return axios.post('/category/'+payload.id+'/update',payload.formData)
            
      },
      deleteCategory({commit,dispatch},id){
        return  axios.get('/category/'+id+'/delete')
            
      },
  },
  getters: {
    categories(state){
      return state.categories;
    },
    categoriesOptions(state){
      return state.categoriesOptions;
    }
  }
};

