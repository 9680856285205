import router from '@/router'
import axios from '@/axios/auth-instance'
export default {

  namespaced:true,
  state: {
      sliders:null
  },
  mutations: {
   
      setSlider(state,sliders){
        state.sliders = sliders;
      }
  },
  actions: {
  		getSliders({commit,dispatch},payload){
        axios.get('/sliders?page='+payload.page+'&&q='+payload.key)
             .then(res=> {
                commit('setSlider',res.data);
             })
             .catch(error=>console.log(error))
  		},
      storeSlider({commint,dispatch},payload){
        return axios.post('/slider/store',payload)
      },
      editSlider({commit,dispatch},id){
       return axios.get('/slider/'+id+'/edit')
             .then(res=> {
               return res;
             })
             .catch(error=>console.log(error))
      },
      updateSlider({commit,dispatch},payload){
        return axios.post('/slider/'+payload.id+'/update',payload.formData)
            
      },
      deleteSlider({commit,dispatch},id){
        return  axios.get('/slider/'+id+'/delete')
            
      },
  },
  getters: {
    sliders(state){
      return state.sliders;
    }
  }
};

