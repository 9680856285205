import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import store from './store/store'
import vSelect from 'vue-select'

/**
 * importing css 
 */
import 'vue-select/dist/vue-select.css';
import 'nprogress/nprogress.css';
import '@/assets/js/app.js';
import '@/assets/css/style.css';
import 'animate.css';

import VueConfirmDialog from 'vue-confirm-dialog';

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component('v-select', vSelect)
Vue.component('pagination', require('laravel-vue-pagination'));
// Vue.prototype.$hostname = 'https://krishi-app.test';
Vue.prototype.$hostname = 'https://krishiapi.demo.momtech.com.np';

// axios.defaults.baseURL = 'https://krishi-app.test/api'
axios.defaults.baseURL = 'https://krishiapi.demo.momtech.com.np/api'

// const reqInterceptor = axios.interceptors.request.use(config => {
//   console.log('Request Interceptor', config)
//   return config
// })
// const resInterceptor = axios.interceptors.response.use(res => {
//   console.log('Response Interceptor', res)
//   return res
// })

// axios.interceptors.request.eject(reqInterceptor)
// axios.interceptors.response.eject(resInterceptor)



Vue.use(Vuelidate);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
