<template>
    <main class="agro-main">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <router-link to="/dashboard"><i class="fa fa-home"></i> Home</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Farms</li>
        </ol>
        <div class="agro-content  animate__animated animate__fadeIn">
            <h3>
                <div class="agro-title-content farm-list">
                    <ul class="nav">
                        <li>
                          <a href="javascript:void(0)" @click="toggleStatus('all')" 
                               :class="{'active':status=='all'}">All Farms
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" @click="toggleStatus('verified')" 
                             :class="{'active':status=='verified'}">Verified
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" @click="toggleStatus('under_review')"
                             :class="{'active':status=='under_review'}">Under Review
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" @click="toggleStatus('not_verified')"
                             :class="{'active':status=='not_verified'}">Not Verified
                          </a>
                        </li>
                    </ul>
                    <p>Total Results: <span class="count-r">{{total_data}}</span></p>
                </div>
                <div class="agro-filter">
                    <div class="search-wrapper">
                        <input type="text" @keyup.enter="paginateData(1)" v-model="search" placeholder="search">
                        <a v-if="is_searched" @click="clearSearch()" class="btn-search">
                            <i @click="clearSearch()" class="fa fa-times"></i>
                        </a>
                        <a v-if="!is_searched" @click="paginateData(1)" class="btn-search">
                            <i class="fa fa-search"></i>
                        </a>
                    </div>
                    <a class="btn-warning" @click="addFarm"><i class="fa fa-plus"></i></a>
                </div>
            </h3>
            <div class="agro-table-content">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Logo</th>
                            <th>Farm Name</th>
                            <th>User</th>
                            <th>Address</th>
                            <th>Reg. No.</th>
                            <th>PAN / VAT No.</th>
                            <th>Staus</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="is_loading">
                            <td colspan="8">
                                <div class="loader">
                                    <img class="logo" :src="require('@/assets/loader.gif')" alt="">
                                </div>
                            </td>
                        </tr>
                        <tr v-if="farms.total==0 && !is_loading">
                            <td colspan="8">No Data found.</td>
                        </tr>
                        <tr v-if="!is_loading" v-for="farm in farms.data">
                            <td>
                                <img v-if="farm.farm_logo_file!=null" class="avatar" :src="imageBase(farm.farm_logo_file)" />
                                <div v-if="farm.farm_logo_file==null" class="avatar">{{firstLetter(farm.farm_name)}}</div>
                            </td>
                            <td>{{farm.farm_name}}</td>
                            <td>{{farm.user_name}}</td>
                            <td>{{farm.address}}</td>
                            <td>{{farm.registration_number}}</td>
                            <td>{{farm.pan_vat_number}}</td>
                            <td class="f-status">
                              <span :class="farm.status">
                                {{farm.status=='verified'?'Verified':(farm.status=='not_verified'?'Not Verified':'Under Review')}}
                              </span>
                            </td>
                            <td>
                                <a class="btn-agro agro-edit" @click="showFarm(farm.id)"><i class="fa fa-eye"></i></a>
                                <a class="btn-agro agro-edit" @click="editFarm(farm.id)"><i class="fa fa-edit"></i></a>
                                <a class="btn-agro agro-delete" @click="deleteFarm(farm.id)"><i class="fa fa-trash"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pagination :data="farms" @pagination-change-page="paginateData" :limit="4"></pagination>
            </div>
        </div>
    </main>
</template>
<script>
export default {

    data() {
        return {
            total_data:0,
            is_loading: false,
            timeout: 500,
            search: '',
            is_searched: false,
            status:'all'
        }
    },
    computed: {
        farms() {
            return !this.$store.getters['farm/farms'] ? {} : this.$store.getters['farm/farms'];
        },
    },
    methods: {
        toggleStatus(_status){
          this.status = _status;
          this.paginateData(1)
        },
        clearSearch() {
            this.search = '';
            this.is_searched = false;
            this.paginateData(1);
        },
        firstLetter(str) {
            if (str != undefined) {

                return str.substr(0, 1)
            }
            return 'F';
        },
        ucfirst(string) {
            if (string != undefined)
                return string[0].toUpperCase() + string.slice(1)
        },
        imageBase(_img) {

            const url = _img.split("/");

            _img = url[url.length - 1];
            _img = '/storage/farms/thumbnail/' + _img;
            return this.$hostname + _img;
        },
        paginateData(page = 1) {

            let _this = this;
            this.is_searched = this.search == '' ? false : true;
            this.is_loading = true;
            this.$store.dispatch('farm/getFarms', { page: page, key: this.search, status:this.status}).then(res => {
                setTimeout(function() {
                    _this.total_data = _this.farms.total;
                    _this.is_loading = false;

                }, _this.timeout)
            });
        },
        editFarm(id) {
            this.$router.push(`farm/${id}/edit`);
        },
        showFarm(id) {
            this.$router.push(`farm/${id}/show`);
        },
        addFarm() {
            this.$router.push(`farm/create`);
        },
        deleteFarm(id) {
            let _this = this;
            this.$confirm({
                title: 'Are you sure?',
                message: 'Once deleted it cannot be recover.',
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$store.dispatch('farm/deleteFarm', id).then((res) => {
                            this.$store.dispatch('farm/getFarms', { page: 1, key: this.search ,status:this.status});
                        });
                    }
                }
            })
        },
    },
    mounted() {
        let _this = this;
        this.is_loading = true;
        this.$store.dispatch('farm/getFarms', { page: 1, key: this.search ,status:this.status}).then(res => {
            setTimeout(function() {
                _this.total_data = _this.farms.total;
                _this.is_loading = false;

            }, _this.timeout)
        });
        console.log('farms', this.farms);
        this.$store.dispatch('user/getAllUsers');

    }
}
</script>
<style>
.count-r{
    font-size: 13px !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    padding: 6px;
    border-radius: 99px;
    color: white;
    background: #3cb57f;
    width: 25px;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}
.farm-list {
    align-items: center;
    flex-direction:column;
}
.farm-list p{
       display: flex;
    font-size: 13px;
    width: 100%;
    padding: 10px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    align-items: center;
}

.farm-list ul {
    list-style: none;
    margin: 0px;
}

.farm-list ul li {
    display: inline-block;
    font-size: 15px;
}

.farm-list ul.nav {
    border: 1px solid #0a8f46;
    background: #f1fff6;
    border-radius: 35px;
    padding: 8px 10px;
    transition: .5s ease-in-out;
    margin: 0;
}

.farm-list ul.nav li a {
    margin: 0;
    background: transparent;
    border-radius: 0;
    text-decoration: none;
    box-shadow: none;
    width: auto;
    padding: 5px 20px;
    color: #000 !important;
    border-radius: 55px;
    transition: .25s ease-in-out;
}

.farm-list ul.nav li a.active {
    background: #3cb57f;
    -webkit-box-shadow: 0 0 13px rgb(0 0 0 / 20%);
    box-shadow: 0 0 13px rgb(0 0 0 / 20%);
    padding: 15px 25px;
    color: #ffffff !important;
}

.f-status span{
    width: 107px;
    font-size: 13px !important; 
    text-align: center;
}

span.under_review {
    display: inline-block;
    background: #FFF0CC;
    padding: 4px 8px;
    color: #F8B15D;
    border-radius: 99px;
    border: 1px solid #F8B15D;
}

span.not_verified {
    display: inline-block;
    background: #FFDBDB;
    padding: 4px 8px;
    color: #F95668;
    border-radius: 99px;
    border: 1px solid #F95668;
}

span.verified {
    border: 1px solid #0a8f46;
    background: #f1fff7;
    color: #0a8f46;
    display: inline-block;
    padding: 4px 8px;
    border-radius: 99px;
}


@media screen and (max-width:800px) {

    .farm-list ul.nav {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        border-radius: 0;
        justify-content: space-around;
        background: transparent;
        padding: 0;
    }

    .farm-list ul.nav li {
        width: 48%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin: 0 0 5px 0;
        padding: 0;
    }

    .farm-list ul.nav li a {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 99px;
        border: 1px solid #3db47f;
        font-size: 14px;
        background: #f1fff6;
        letter-spacing: -0.5px;
        margin-bottom: 7px;
    }

    .farm-list ul.nav li a:hover,
    .farm-list ul.nav li a.active {
        background: #3db47f;
        color: #FFF !important;
    }
}
</style>