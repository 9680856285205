<template>
	<main class="agro-main">
     <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/dashboard"><i class="fa fa-home"></i>  Home</router-link></li>
      <li class="breadcrumb-item"> <router-link to="/user">Users</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">{{isEditing==true?'Edit':'Add'}} User</li>
    </ol>
    <div class="agro-content agro-form">
        <h3> <span>{{isEditing==true?'EDIT':'ADD'}} USER</span></h3>
        <form class="row"  @submit.prevent="isEditing==true?updateUser():createUser()" enctype="multipart/form-data">

          <div class="form-group col-md-4" :class="{invalid:$v.fields.name.$error}">
            <label for="name">Full Name <span style="color:red">*</span></label>
            <input  @blur="$v.fields.name.$touch()" v-model="fields.name" type="text" class="form-control">
            <p class="error" v-if="$v.fields.name.$error &&  !$v.fields.name.required">This field must not be empty.</p>
          </div>
          
          <div class="form-group  col-md-4" :class="{invalid:$v.fields.role.$error}">
            <label for="role">Role <span style="color:red">*</span></label>
            <select  v-model="fields.role"  class="form-control">
              <option value="">Select Please</option>
              <option v-if="role=='superadmin'" value="superadmin">Super Admin</option>
              <option v-if="role=='superadmin' || (role='admin' && id==auth_id)" value="admin">Admin</option>
              <option value="user">User</option>
            </select>
            <!-- <input type="text"  @blur="$v.fields.role.$touch()" v-model="fields.role" class="form-control"> -->
            <p class="error" v-if="$v.fields.role.$error &&  !$v.fields.role.required">This field must not be empty.</p>
          </div>
          
           <div class="form-group  col-md-4" :class="{invalid:$v.fields.mobile_no.$error}">
            <label for="">Mobile No. <span style="color:red">*</span></label>
            <input @blur="$v.fields.mobile_no.$touch()" v-model="fields.mobile_no" type="text" class="form-control" autofocus="">
            <p class="error" v-if="$v.fields.mobile_no.$error &&  !$v.fields.mobile_no.required">This field must not be empty.</p>
          </div>
           <div class="form-group  col-md-4" :class="{invalid:$v.fields.email.$error}">
            <label for="inputEmail">Email address <span style="color:red">*</span></label>
            <input @blur="$v.fields.email.$touch()" v-model="fields.email" type="email" id="inputEmail" class="form-control" autofocus="">
            <p class="error" v-if="$v.fields.email.$error && !$v.fields.email.email">Please provide a valid email address.</p>
            <p class="error" v-if="$v.fields.email.$error &&  !$v.fields.email.required">This field must not be empty.</p>
          </div>
           <div v-if="!isEditing" class="form-group  col-md-4" :class="{invalid:fields.password=='' && password_touched}">
            <label for="">Password<span style="color:red">*</span></label>
            <input @blur="passwordTouch" v-model="fields.password" type="password" class="form-control" autofocus="">
            <p class="error"  v-if="fields.password=='' && password_touched">This field must not be empty.</p>
          </div>

           <div class="form-group  col-md-4" >
            <label for="">Address </label>
            <input @blur="$v.fields.address.$touch()" v-model="fields.address" type="text" class="form-control" autofocus="">
          </div>

           <div class="form-group  col-md-4" >
            <label for="">Website </label>
            <input @blur="$v.fields.website.$touch()" v-model="fields.website" type="text" class="form-control" autofocus="">
          </div>

          <div v-if="!isEditing" class="form-group col-md-4"></div>
          <div v-if="!isEditing" class="form-group col-md-4"></div>
          <div class="form-group  col-md-4">
            <label for="">Avatar</label>
            <div class="file-container">
              <div class="file-preview"  @click="fileInputTrigger">
                <a href="javascript:void(0);"><i class="fa fa-image"></i></a>
                <img v-if="fields.avatar_file!=null" :src="_avatar" class="image-view">
              </div>
              <input  @change="handleImageSelected" type="file" 
                      ref="fileInput" class="hide-element" 
                      accept="image/gif, image/jpeg, image/png">
              <!-- <p class="error" v-if="fields.avatar_file==null && file_touched">required.</p> -->
            </div>
          </div>
          <div class="form-group col-md-12 submit-container">
            <button type="submit" class="btn agro-submit-btn">{{isEditing==true?'Update':'Submit'}} </button>
            <div class="loader" v-if="is_loading" style="width:auto">
              <img class="logo" :src="require('@/assets/loader.gif')" alt="">
            </div>
          </div>
        </form>
    </div>
  </main>
</template>
<script>
  import { ref } from "vue";
  import {required,email} from 'vuelidate/lib/validators';
  export default {
    data(){
      return {
          auth_id:0,
          id:this.$route.params.id,
          isEditing : false,
          is_loading:false,
          password_touched:false,
          file_touched:false,
          file:null,
          role:'',
          is_changed:false,
          fields:{
              name:null,
              role:null,
              email:null,
              password:'',
              address:null,
              mobile_no:null,
              website:null,
              avatar:null,
              avatar_file:null
          }
      }
    },
    validations:{
      fields:{
        name:{required},
        role:{required},
        email:{required,email},
        mobile_no:{required},
      }
    },
    computed:{
      _avatar(){
        if(this.is_changed)
          return this.fields.avatar_file;

        return this.$hostname+this.fields.avatar_file;
      },
      auth(){
        return this.$store.getters['auth/auth'];
      }
      
    },
    watch:{

      file(file,oldFile){
        this.is_changed = true;
        if(!(file instanceof File)) return;
        let _this = this;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener('load',function(){
          _this.fields.avatar = fileReader.result;
          _this.fields.avatar_file = fileReader.result;
        })
      }
    },
    methods:{
      createUser(){
          this.is_loading = true;
          this.password_touched = true;
          this.file_touched = true;
          this.$v.$touch();
          if (this.$v.$invalid && this.fields.password==null) {
              this.is_loading = false;
              return;
          }
          let data = new FormData();
              data.append('name',this.fields.name);
              data.append('role',this.fields.role);
              data.append('email',this.fields.email);
              data.append('password',this.fields.password);
              data.append('address',this.fields.address);
              data.append('mobile_no',this.fields.mobile_no);
              data.append('website',this.fields.website);
              data.append('file',this.file);


              console.log('form data',data);

          this.$store.dispatch('user/storeUser',data)
              .then(res=> {
                console.log('result',res);
                   this.is_loading = false;
                   this.$router.replace('/user')
               })
              .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                }else if (error.request) {
                    console.log(error.request);
                }else {
                    console.log('Error', error.message);
                }
                this.is_loading = false;

            });


      }, 
      updateUser(){

          // console.log('nnn',this.fields.role);
          // return;
          let _this = this;
          this.is_loading = true;
          this.file_touched = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
              this.is_loading = false;
              return;
          }
         let data = new FormData();
              data.append('name',this.fields.name);
              data.append('role',this.fields.role);
              data.append('email',this.fields.email);
              data.append('password',this.fields.password);
              data.append('address',this.fields.address);
              data.append('mobile_no',this.fields.mobile_no);
              data.append('website',this.fields.website);
              data.append('file',this.file);



          this.$store.dispatch('user/updateUser',{id:this.id,formData:data})
              .then(res=> {
                      this.is_loading = false;
                      this.$confirm({
                          title: 'Success',
                          message: 'User Successfully Updated.',
                          button: {
                            yes: 'OK',
                          },
                          callback: confirm => {
                            if (confirm) {
                              this.$router.replace('/user')
                            }
                          }
                        }
                       )

               })
              .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                }else if (error.request) {
                    console.log(error.request);
                }else {
                    console.log('Error', error.message);
                }
                this.is_loading = false;

            });

      },
      handleImageSelected(event){
        console.log('event',event);
        if(event.target.files.length==0){
          this.file = null;
          this.fields.avatar = null;
          return;
        } 
        this.file = event.target.files[0];

        this.file_touched = true;

      },
       passwordTouch(){
          this.password_touched = true;
       },
      removeImage(){
        this.file = null;
        this.fields.avatar = null;
        this.fields.avatar_file = null;
      },
      fileInputTrigger(){
        this.$refs.fileInput.click();
      },
      getAndSetData(){
          // console.log(this.id);
            this.$store.dispatch('user/editUser',this.id).then((res)=>{
              // console.log('edit',res);
              this.fields.name = res.data.name;
              this.fields.role = res.data.role;
              this.fields.email = res.data.email;
              this.fields.address = res.data.address;
              this.fields.mobile_no = res.data.mobile_no;
              this.fields.website = res.data.website;
              this.fields.avatar = res.data.avatar;
              this.fields.avatar_file = res.data.avatar_file;

            })
        }
    },
    mounted(){
     let id = this.$route.params.id
     this.isEditing = (id===undefined)?false:true;
     if(this.isEditing)
     this.getAndSetData();


     this.role = this.auth.role;
     this.auth_id = this.auth.user_id;
     
    }
  }

</script>
<style></style>