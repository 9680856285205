import router from '@/router'
import axios from '@/axios/auth-instance'
export default {

  namespaced:true,
  state: {
      blogs:null
  },
  mutations: {
   
      setBlog(state,blogs){
        state.blogs = blogs;
      }
  },
  actions: {
  		getBlogs({commit,dispatch},payload){
        axios.get('/blogs?page='+payload.page+'&&q='+payload.key)
             .then(res=> {
                commit('setBlog',res.data);
             })
             .catch(error=>console.log(error))
  		},
      storeBlog({commint,dispatch},payload){
        return axios.post('/blog/store',payload)
      },
      editBlog({commit,dispatch},id){
       return axios.get('/blog/'+id+'/edit')
             .then(res=> {
               return res;
             })
             .catch(error=>console.log(error))
      },
      updateBlog({commit,dispatch},payload){
        return axios.post('/blog/'+payload.id+'/update',payload.formData)
            
      },
      deleteBlog({commit,dispatch},id){
        return  axios.get('/blog/'+id+'/delete')
            
      },
  },
  getters: {
    blogs(state){
      return state.blogs;
    }
  }
};

