import router from '@/router'
import axios from '@/axios/auth-instance'
export default {

  namespaced:true,
  state: {
      users:null,
      usersOption:null,
  },
  mutations: {
   
      setUser(state,users){
        state.users = users;
      },

      setUserOptions(state,users){
        state.usersOption = users;
      }
  },
  actions: {
     getAllUsers({commit,dispatch}){
          axios.get('/all-users')
             .then(res=> {
                commit('setUserOptions',res.data);
             })
             .catch(error=>console.log(error))
      },
      changePassword({commit,dispatch},payload){
        return axios.post('/user/change-password',payload)

      },
  		getUsers({commit,dispatch},payload){
        axios.get('/users?page='+payload.page+'&&q='+payload.key+'&&role='+payload.role)
             .then(res=> {
                commit('setUser',res.data);
             })
             .catch(error=>console.log(error))
  		},
      storeUser({commint,dispatch},payload){
        return axios.post('/user/store',payload)
      },
      editUser({commit,dispatch},id){
       return axios.get('/user/'+id+'/edit')
             .then(res=> {
               return res;
             })
             .catch(error=>console.log(error))
      },
      updateUser({commit,dispatch},payload){
        return axios.post('/user/'+payload.id+'/update',payload.formData)
            
      },
      deleteUser({commit,dispatch},id){
        return  axios.get('/user/'+id+'/delete')
            
      },
  },
  getters: {
    users(state){
      return state.users;
    },
    usersOption(state){
      return state.usersOption;
    }
  }
};

