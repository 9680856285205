<template>
	<main class="agro-main">
     <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/dashboard"><i class="fa fa-home"></i>  Home</router-link></li>
      <li class="breadcrumb-item"> <router-link to="/category">Categories</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">{{isEditing==true?'Edit':'Add'}} Category</li>
    </ol>
    <div class="agro-content agro-form">
        <h3> <span>{{isEditing==true?'EDIT':'ADD'}} Category</span></h3>
        <form class="row category-form"  @submit.prevent="isEditing==true?updateCategory():createCategory()" enctype="multipart/form-data">

          <div class="form-group col-md-4" :class="{invalid:$v.fields.name.$error}">
            <label for="name">Name <span style="color:red">*</span></label>
            <input  @blur="$v.fields.name.$touch()" v-model="fields.name" type="text" class="form-control">
            <p class="error" v-if="$v.fields.name.$error &&  !$v.fields.name.required">This field must not be empty.</p>
          </div>
          <div class="form-group col-md-4">
              <strong for="name">Status</strong>
              <label class="switch">
                <input type="checkbox" checked name="status" value="1" v-model="fields.status" >
                <span class="slider round"></span>
              </label>
          </div>
       
          <div class="form-group col-md-12">
            <button type="submit" class="btn agro-submit-btn">{{isEditing==true?'Update':'Submit'}}</button>
          </div>
        </form>
    </div>
  </main>
</template>
<script>
  import { ref } from "vue";
  import {required,email} from 'vuelidate/lib/validators';
  export default {
    data(){
      return {
          id:this.$route.params.id,
          isEditing : false,
          fields:{
              name:null,
              status:false
          }
      }
    },
    validations:{
      fields:{
        name:{required},
      }
    },
    computed:{
    },
    watch:{

    },
    methods:{
      createCategory(){

          this.$v.$touch();
          if (this.$v.$invalid) {
              return;
          }
          let data = new FormData();
              data.append('name',this.fields.name);
              data.append('status',this.fields.status);


              console.log('form data',data);

          this.$store.dispatch('category/storeCategory',data)
              .then(res=> {
                console.log('result',res);
                   this.$router.replace('/category')
               })
              .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                }else if (error.request) {
                    console.log(error.request);
                }else {
                    console.log('Error', error.message);
                }
            });


      }, 
      updateCategory(){
        let _this = this;
          this.$v.$touch();
          if (this.$v.$invalid) {
              return;
          }
         let data = new FormData();
              data.append('name',this.fields.name);
              data.append('status',this.fields.status);


          this.$store.dispatch('category/updateCategory',{id:this.id,formData:data})
              .then(res=> {
                      this.$confirm({
                          title: 'Success',
                          message: 'Category Successfully Updated.',
                          button: {
                            yes: 'OK',
                          },
                          callback: confirm => {
                            if (confirm) {
                              this.$router.replace('/category')
                            }
                          }
                        }
                       )

               })
              .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                }else if (error.request) {
                    console.log(error.request);
                }else {
                    console.log('Error', error.message);
                }
            });

      },
      getAndSetData(){
          // console.log(this.id);
            this.$store.dispatch('category/editCategory',this.id).then((res)=>{
              // console.log('edit',res);
              this.fields.name = res.data.name;
              this.fields.status = res.data.status=='0'?false:true;

            })
        }
    },
    mounted(){
     let id = this.$route.params.id
     this.isEditing = (id===undefined)?false:true;
     if(this.isEditing)
     this.getAndSetData();
     
    }
  }

</script>
<style>
    .category-form .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }

    .category-form .switch input { 
      opacity: 0;
      width: 0;
      height: 0;
    }

    .category-form .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .category-form .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .category-form input:checked + .slider {
      background-color: #2196F3;
    }

    .category-form input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    .category-form input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    .category-form .slider.round {
      border-radius: 34px;
    }

    .category-form .slider.round:before {
      border-radius: 50%;
    }

    .category-form  strong{
        display: block;
        font-size: 14px;
        font-weight: normal;
        padding-bottom: 10px;
    }

</style>