import router from '@/router'
import axios from '@/axios/auth-instance'
export default {

  namespaced:true,
  state: {
      programs:null,
      programsOption:null,
  },
  mutations: {
   
      setProgram(state,programs){
        state.programs = programs;
      },
      setProgramOptions(state,program){
        state.programsOption = program;
      }  
  },
  actions: {
  		getPrograms({commit,dispatch},payload){
        axios.get('/programs?page='+payload.page+'&&q='+payload.key)
             .then(res=> {
                commit('setProgram',res.data);
             })
             .catch(error=>console.log(error))
  		},
      storeProgram({commint,dispatch},payload){
        return axios.post('/program/store',payload)
      },
      editProgram({commit,dispatch},id){
       return axios.get('/program/'+id+'/edit')
             .then(res=> {
               return res;
             })
             .catch(error=>console.log(error))
      },
      updateProgram({commit,dispatch},payload){
        return axios.post('/program/'+payload.id+'/update',payload.formData)
            
      },
      deleteProgram({commit,dispatch},id){
        return  axios.get('/program/'+id+'/delete')
            
      },
  },
  getters: {
    programs(state){
      return state.programs;
    }
  }
};

