<template>
	<form class="form-signin" @submit.prevent="onSubmit">
    <div class="login-form">
      <div class="text-center mb-4">
        <img class="logo" :src="require('@/assets/logo-org.png')" alt="">
        <!-- <h1 class="h3 mb-3 font-weight-normal">Login Now</h1> -->
      </div>
      <div class="error-server" v-if="serverError">
        Invalid Credential.
      </div>
      <div class="form-label-group" :class="{invalid:$v.email.$error}">
        <input @blur="$v.email.$touch()" v-model="email" type="email" id="inputEmail" class="form-control" placeholder="Email address" autofocus="">
        <p class="error" v-if="$v.email.$error && !$v.email.email">Please provide a valid email address.</p>
        <p class="error" v-if="$v.email.$error &&  !$v.email.required">This field must not be empty.</p>
      </div>
      <div class="form-label-group" :class="{invalid:$v.password.$error}">
        <input @blur="$v.password.$touch()" v-model="password" type="password" id="inputPassword" class="form-control" placeholder="Password">
        <p class="error" v-if="$v.password.$error &&  !$v.password.required">This field must not be empty.</p>
      </div>
      <button class="signin-btn" type="submit">Sign in
          <div class="loader" v-if="is_loading">
            <img class="logo" :src="require('@/assets/loader.gif')" alt="">
          </div>
      </button>
 
      <p class="mt-5 mb-3 text-muted text-center">© Agriculture of Bagchaur Municipality</p>
    </div>
  </form>
</template>

<script>
  import {required,email} from 'vuelidate/lib/validators';
  export default {
    data () {
      return {
        is_loading:false,
        serverError:false,
        email:'',
        password:''
      }
    },
    validations:{
      email:{
          required,
          email
      },
      password:{
          required
      }
    },
    methods:{
      onSubmit(){
        this.is_loading = true;
        let _this = this;
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.is_loading = false;
            return;
        }
        const authData = {
          email: this.email,
          password: this.password,
        }
        this.$store.dispatch('auth/login',authData)
                   .then(res=>{
                          setTimeout(function(){
                            _this.is_loading = false;
                          },500)
                   })
                   .catch(error=>{
                        this.serverError = true;
                          setTimeout(function(){
                               _this.serverError = false;
                          },3000)
                          setTimeout(function(){
                               _this.is_loading = false;
                             },500)
                   });
                  
      

      }
    },
    mounted(){
    }
  }

</script>
  



<style type="text/css" src ="@/assets/css/component-wise/login.css"></style>



