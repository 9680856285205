import router from '@/router'
import axios from '@/axios/auth-instance'
export default {

  namespaced:true,
  state: {
      dashboard_reports:null,
  },
  mutations: {
   
      setReports(state,data){
        state.dashboard_reports = data;
      },
  },
  actions: {
     loadReports({commit,dispatch}){
           axios.get('/dashboard-report')
             .then(res=> {
                commit('setReports',res.data);
             })
             .catch(error=>console.log(error))
      },
  },
  getters: {
    getReports(state){
      return state.dashboard_reports;
    }
  }
};

