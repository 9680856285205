import router from '@/router'
import axios from '@/axios/auth-instance'
export default {

  namespaced:true,
  state: {
      menu_toggle:false,
  },
  mutations: {
   
      toggleMenu(state){
        state.menu_toggle = state.menu_toggle?false:true;
      },
  },
  actions: {
     toggleNavMenu({commit,dispatch}){
          commit('toggleMenu');
      },
  },
  getters: {
    menuState(state){
      return state.menu_toggle;
    }
  }
};

