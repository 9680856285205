<template>
	<main class="agro-main">
   
     <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/dashboard"><i class="fa fa-home"></i>  Home</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">Users</li>
    </ol>
    <div class="agro-content">
      <h3> 
        <div class="agro-title-content">
          <div class="agro-title-content farm-list">
              <ul class="nav">
                  <li>
                    <a href="javascript:void(0)" @click="toggleStatus('all')" 
                         :class="{'active':status=='all'}">All Users
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="toggleStatus('admin')" 
                       :class="{'active':status=='admin'}">Admin
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="toggleStatus('user')"
                       :class="{'active':status=='user'}">User
                    </a>
                  </li>
              </ul>
                    <p>Total Results: <span class="count-r">{{total_data}}</span></p>
          </div>
        </div>
        <div class="agro-filter">
            <div class="search-wrapper">
            <input type="text" @keyup.enter="paginateData(1)" v-model="search" placeholder="search">
            <a  v-if="is_searched" @click="clearSearch()" class="btn-search" >
              <i @click="clearSearch()" class="fa fa-times"></i>
            </a> 
            <a v-if="!is_searched" @click="paginateData(1)"  class="btn-search">
              <i class="fa fa-search"></i>
            </a>
          </div>
          <a class="btn-warning" @click="addUser"><i class="fa fa-plus"></i></a>
        </div>
      </h3>
      <div class="agro-table-content">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Avatar</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Mobile No.</th>
              <th>Address</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
             <tr  v-if="is_loading">
              <td colspan="7" > 
                   <div class="loader">
                    <img class="logo" :src="require('@/assets/loader.gif')" alt="">
                  </div>
              </td>
            </tr>
            <tr v-if="users.total==0  && !is_loading">
              <td colspan="7">No Data found.</td>
            </tr>
            <tr v-if="!is_loading" v-for="user in users.data">
              <td>
                <img v-if="user.avatar_file!=null" class="avatar" :src="imageBase(user.avatar_file)"/>
                <div v-if="user.avatar_file==null" class="avatar">{{(user.name).substr(0,1)}}</div>
              </td>
              <td>{{user.name}}</td>
              <td>{{user.email}}</td>
              <td><span :class="user.role">{{ucfirst(user.role)}}</span></td>
              <td>{{user.mobile_no}}</td>
              <td>{{user.address}}</td>
              <td>
                <a v-if="role=='superadmin' || (user.role=='user' && role=='admin') || (role=='admin' && user.role=='admin' && user.id==auth_id)" class="btn-agro agro-edit" @click="editUser(user.id)"><i class="fa fa-edit"></i></a>
                <a v-if="role=='superadmin' || (role=='admin' && user.role=='user') || (role=='admin' && user.role=='admin' && user.id==auth_id)" class="btn-agro agro-edit pass" @click="changePassword(user.id)">
                  <i class="fa fa-key"></i></a>
                <a v-if="role=='superadmin' || (role=='admin' && user.role=='user')" class="btn-agro agro-delete" @click="deleteUser(user.id)">
                  <i class="fa fa-trash"></i></a>
                <a class="btn-agro agro-edit leaf" @click="myFarms(user.farm_id)"><i class="fa fa-leaf"></i></a>
              </td>
            </tr>
           
          </tbody>
        </table>
        <pagination :data="users" @pagination-change-page="paginateData" :limit="4"></pagination>
      </div>
    </div>
  </main>
</template>
<script>
  export default {

    data(){
      return {
        total_data:0,
        status:'all',
        is_loading:false,
        timeout:500,
        auth_id:0,
        search:'',
        role:'',
        is_searched:false
      }
    },
    computed:{
      users(){
        return !this.$store.getters['user/users']?{}:this.$store.getters['user/users'];
      },
      auth(){
        return this.$store.getters['auth/auth'];
      }
    },
    methods:{
      toggleStatus(_status){
        this.status = _status;
        this.paginateData(1);
      },
      clearSearch(){
        this.search = '';
        this.is_searched = false;
        this.paginateData(1);
      },
      ucfirst(string){
        return string[0].toUpperCase() + string.slice(1)
      },
      imageBase(_img){
        const url = _img.split("/");

        _img = url[url.length-1];
        _img = '/storage/avatars/thumbnail/'+_img;

        return this.$hostname+_img;
      },
      paginateData(page = 1){

        let _this = this;
        this.is_searched = this.search==''?false:true;
        this.is_loading = true;
        this.$store.dispatch('user/getUsers',{page:page,key:this.search,role:this.status}).then(res=>{
             setTimeout(function(){
                 _this.total_data = _this.users.total;
                 _this.is_loading = false;

             },_this.timeout)
        });
      },
      changePassword(id){
        this.$router.push(`user/${id}/change-password`);
      },
      myFarms(id){
        this.$router.push(`farm/${id}/show`);
      },
      editUser(id){
        this.$router.push(`user/${id}/edit`);
      },
      addUser(){
        this.$router.push(`user/create`);
      },
      deleteUser(id){
        let _this = this;
        this.$confirm(
          {
            title: 'Are you sure?',
            message: 'Once deleted it cannot be recover.',
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                   this.$store.dispatch('user/deleteUser',id).then((res)=>{
                      this.$store.dispatch('user/getUsers',{page:1,key:this.search,role:'all'});
                   });
              }
            }
          }
        )
      },
    },
    mounted(){
      let _this = this;
      this.is_loading = true;
      this.$store.dispatch('user/getUsers',{page:1,key:this.search,role:'all'}).then(res=>{
         setTimeout(function(){
                 _this.total_data = _this.users.total;
                 _this.is_loading = false;

             },_this.timeout)
      });
      this.auth_id = this.auth.user_id;
      this.role = this.auth.role;
      console.log('users',this.users);
    }
  }

</script>
<style>

  span.user {
    border: 1px solid #3db47f;
    border-radius: 5px;
    padding: 3px;
    width: 100px;
    font-size: 12px;
    color: #3db47f;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span.superadmin,
  span.admin{
    border: 1px solid red;
    border-radius: 5px;
    padding: 3px;
    width: 100px;
    font-size: 12px;
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span.superadmin{
    border: 1px solid red;
    color:red;
  }
</style>